import { parse, ParsedUrlQuery, stringify } from 'querystring';
import { Url } from 'url';
import { isServer } from './isServer';

export type UrlTypes = string | Partial<Url> | undefined;

const anchorRegexp = /#[0-9a-zа-я-_.]+/i;

const matchAnchor = (href: string) => href.match(anchorRegexp)?.[0] || '';
const matchSearch = (href: string) => href.replace(/[/0-9_:a-z-.[\]%]*\.?[_%#0-9a-z-[\]]*/i, '').replace(/^\??/i, '');
const matchUrl = (href: string) => (href.match(/[/0-9_:a-z-.[\]%]*/i)?.[0] || '');

export const withUtm = <T extends UrlTypes>(href: T, _query?: ParsedUrlQuery) => {
    const query = _query ? _query : isServer ? {} : parse(location.search.replace(/^\?/, ''));

    if (href) {
        let url: string;
        let anchor: string;
        let hrefSearch: string;
        if (typeof href === 'string') {
            anchor = matchAnchor(href);
            // на всякий случай очищаю от якорей, избегаем некорректных url
            url = matchUrl(href)!.replace(anchor, '');
            hrefSearch = matchSearch(href);
        } else {
            if (!href.pathname) {
                return href;
            }
            anchor = matchAnchor(href.pathname);
            // на всякий случай очищаю от якорей, избегаем некорректных url
            url = href.pathname.replace(anchor, '');
            hrefSearch = (new URLSearchParams(href.query as Record<string, string>)).toString();
        }

        const allUtm: ParsedUrlQuery = hrefSearch ? parse(hrefSearch) : {};

        Object.keys(query).forEach(key => {
            if (/^utm_/.test(key)) {
                if (!allUtm[key]) {
                    allUtm[key] = query[key];
                }
            }
        });

        return Object.keys(allUtm).length ? `${url}?${stringify(allUtm)}${anchor}` : `${url}${anchor}`;
    }

    return href;
};
