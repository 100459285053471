import { SVGProps } from 'react';
import * as React from 'react';

export const SearchSvg = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.702 3.304c-.547 1.01-.585 1.642-.662 2.905-.026.425-.041.858-.041 1.292 0 .433.015.867.04 1.292.078 1.263.116 1.895.663 2.905.357.662 1.439 1.743 2.1 2.1 1.01.547 1.642.585 2.906.663a21.21 21.21 0 0 0 2.583 0c1.264-.078 1.895-.116 2.905-.662.126-.068.266-.162.414-.274l3.683 3.682a1 1 0 0 0 1.414-1.414l-3.683-3.683a3.32 3.32 0 0 0 .273-.412c.546-1.01.585-1.642.662-2.905.026-.425.041-.859.041-1.292 0-.434-.015-.867-.04-1.292-.078-1.263-.117-1.895-.663-2.905-.358-.662-1.439-1.743-2.1-2.101C11.186.657 10.554.618 9.29.541A21.229 21.229 0 0 0 8 .5c-.433 0-.866.015-1.291.041-1.264.077-1.896.116-2.905.662-.662.358-1.744 1.44-2.101 2.101Zm5.128-.767C7.22 2.514 7.612 2.5 8 2.5c.387 0 .78.014 1.169.037 1.241.076 1.483.105 2.076.425l.02.012c.013.009.032.02.055.037.047.032.106.076.175.132.138.113.295.254.452.41.156.157.298.314.41.453a3.026 3.026 0 0 1 .18.25c.321.592.35.834.426 2.075.024.39.037.782.037 1.17 0 .387-.013.78-.037 1.17-.076 1.24-.105 1.483-.425 2.075l-.012.02a3.032 3.032 0 0 1-.17.23 5.962 5.962 0 0 1-.41.452 6.025 6.025 0 0 1-.451.41 3.124 3.124 0 0 1-.25.181c-.593.32-.835.35-2.076.425-.39.024-.782.038-1.17.038-.387 0-.78-.014-1.17-.038-1.24-.075-1.483-.104-2.075-.425l-.02-.011a3.125 3.125 0 0 1-.23-.17 6.015 6.015 0 0 1-.452-.41 5.977 5.977 0 0 1-.41-.452 3.06 3.06 0 0 1-.17-.23l-.011-.02c-.32-.592-.35-.835-.425-2.075-.024-.39-.037-.783-.037-1.17 0-.388.013-.78.037-1.17.076-1.24.104-1.483.425-2.076l.012-.02.036-.055c.033-.047.077-.105.133-.174.112-.14.254-.296.41-.452.157-.157.313-.298.452-.41a3.05 3.05 0 0 1 .25-.182c.592-.32.835-.349 2.076-.425Z"
            fill="#8D969F"
        />
    </svg>
);
