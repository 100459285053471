export const colorConst = {
    greyscale0: '#ffffff',
    greyscale100: '#f2f3f7',
    greyscale200: '#e2e5eb',
    greyscale300: '#bbc1c7',
    greyscale400: '#969fa8',
    greyscale500: '#626c77',
    greyscale600: '#434a51',
    greyscale700: '#2c3135',
    greyscale800: '#1d2023',
    greyscale900: '#000000',
    blackberryLightest: '#e7eafa',
    blackberryLight: '#6384e0',
    blackberryNormal: '#014fce',
    blackberryDark: '#003db7',
    blackberryDarkest: '#002094',
    blueberryLightest: '#e1f3fe',
    blueberryLight: '#45b6fc',
    blueberryNormal: '#0097fd',
    blueberryDark: '#007cff',
    blueberryDarkest: '#0048aa',
    backgroundOverlayDark: '#1D2023E5',
    backgroundOverlayLight: '#1D202366',
    mintLightest: '#dff9f5',
    mintLight: '#00d9bc',
    mintNormal: '#00c19b',
    mintDark: '#03a17b',
    mintDarkest: '#00724d',
    appleLightest: '#e8faeb',
    appleLight: '#74df8b',
    appleNormal: '#26cd58',
    appleDark: '#04aa42',
    appleDarkest: '#027722',
    limeLightest: '#f8fee7',
    limeLight: '#d3f36b',
    limeNormal: '#c1eb1d',
    limeDark: '#a6c100',
    limeDarkest: '#808201',
    bananaLightest: '#fffde8',
    bananaLight: '#fdf177',
    bananaNormal: '#fbe739',
    bananaDark: '#fac031',
    bananaDarkest: '#f37f19',
    orangeLightest: '#fbe9e7',
    orangeLight: '#ffa080',
    orangeNormal: '#f95721',
    orangeDark: '#e04a17',
    orangeDarkest: '#ba360a',
    raspberryLightest: '#ffe4e9',
    raspberryLight: '#f55f7e',
    raspberryNormal: '#ea1f49',
    raspberryDark: '#c51345',
    raspberryDarkest: '#8e003f',
    cranberryLightest: '#f9e7f0',
    cranberryLight: '#e677ad',
    cranberryNormal: '#e54887',
    cranberryDark: '#bb4079',
    cranberryDarkest: '#7f3363',
    plumLightest: '#f0e7f0',
    plumLight: '#a86ea7',
    plumNormal: '#883888',
    plumDark: '#6d2d79',
    plumDarkest: '#471c61',
    torchRed: '#ff0032',
    regentGray: '#8d969f',
    mischka: '#ced4dc',
    alabaster: '#fafafa',
    marigoldYellow: '#fad67d',
    salmon: '#fa8a64',
    malachite: '#12b23f',
    tiaMaria: '#db400c',
    scienceBlue: '#0070e5',
    dodgerBlue: '#3396ff',
    grayChateau: '#abb2b9',
    paleSky: '#6e7782',
    whisper: '#f8f8fb',
    fuchsiaPink: '#b767b7',
    nevada: '#6a7278',
    catPerson: '#636b72',
    outerSpace: '#2e3338',
    transparent: 'transparent',
    linkWater50: 'rgba(188, 195, 208, 0.5)',
    linkWater40: 'rgba(188, 195, 208, 0.4)',
    linkWater25: 'rgba(188, 195, 208, 0.25)',
    cinder40: 'rgba(29, 32, 35, 0.4)',
    cinder60: 'rgba(29, 32, 35, 0.6)',
    solitude20: 'rgba(242, 243, 247, 0.2)',
    solitude12: 'rgba(242, 243, 247, 0.12)',
    white92: 'rgba(255, 255, 255, 0.92)',
    white46: 'rgba(255, 255, 255, 0.46)',
    regentGray35: 'rgba(127, 140, 153, 0.35)',
    regentGray10: 'rgba(127, 140, 153, 0.1)',
    mirageBlue25: 'rgba(98, 108, 119, 0.25)',
    mirageBlue15: 'rgba(98, 108, 119, 0.15)',
    black92: 'rgba(0, 0, 0, 0.92)',
};
