import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { StyledAccordion, AccordionItem } from './accordion.styles';
import { GeolocationData, RegionData } from '../../../models/geolocation';
import { selectGeoModalOpened } from '../../../redux/ui/ui-selectors';
import { ArrowDownIcon } from '../../svg-components/geolocation/arrow-down';
import GeolocationFoundList from '../geolocation-found-list/geolocation-found-list';
import { GeolocationHeader } from '../geolocation-header/geolocation-header';
import { SearchInput } from '../search-input/search-input';
import { SearchNotFound } from '../search-not-found/search-not-found';

interface AccordionProps {
    regions?: RegionData;
    searchText: string;
    onTextValue: (value: string) => void;
    getListCities: (id: number | null) => void;
    cities?: Array<GeolocationData>;
    onClose: () => void;
    setGeolocation: (params: GeolocationData) => void;
    debouncedSearch: string;
    foundGeolocation: Array<GeolocationData>;
    setGeolocationFromSearch: (params: GeolocationData, regionId?: number) => void;
    setIsOtherCities?: () => void;
    onQueryClear: () => void;
    loadingCities: boolean;
    geolocation?: GeolocationData;
}

interface Item {
    id: number | null;
    active: boolean;
}

interface ScrollParamsInterface {
    shift: number;
    add: number;
    top: number;
}

export const Accordion: React.FC<AccordionProps> = ({
    regions,
    searchText,
    onTextValue,
    getListCities,
    cities,
    onClose,
    setGeolocation,
    debouncedSearch,
    foundGeolocation,
    setGeolocationFromSearch,
    setIsOtherCities,
    onQueryClear,
    loadingCities,
    geolocation,
}) => {
    const newRegions = Object.values(regions || {}).reduce((acc, val) => acc.concat(val), []);

    const [tabs, setTabs] = useState<Array<Item>>(Array.from(newRegions, (arg) => ({ active: false, id: arg.id })));
    const [scrollParams, setScrollParams] = useState<ScrollParamsInterface>({ shift: 0, add: 0, top: 0 });

    const isModalOpened = useSelector(selectGeoModalOpened);

    const refRegion = useRef<HTMLInputElement>(null);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const id = geolocation?.parent?.id || geolocation?.id;
        setTabs((args) => args.map((item) => (item.id === id ? { ...item, active: true } : item)));
    }, []);

    useEffect(() => {
        if (refRegion && refRegion.current && ref && ref.current) {
            const coords = refRegion.current.getBoundingClientRect();
            const activeCityIndex = cities?.map((item) => item.id).indexOf(geolocation?.id || null) || -1;
            const shift = (activeCityIndex > -1 ? activeCityIndex + 1 : 0) * 44;

            setScrollParams({
                shift,
                add: (cities?.length || 0) * 44,
                top: coords.top,
            });
        }
    }, [isModalOpened]);

    useEffect(() => {
        if (isModalOpened) {
            ref.current?.scroll({
                top: scrollParams.top - 154 + scrollParams.shift,
                left: 0,
                behavior: 'smooth',
            });
        }
    }, [scrollParams]);

    const handleChangeItemClick = (id: number | null) => {
        getListCities(id);
        setTabs((args) =>
            args.map((item) => (item.id === id ? { ...item, active: !item.active } : { ...item, active: false })),
        );
    };

    const handleClearInput = () => {
        onQueryClear && onQueryClear();
        if (window && window.document) {
            window.document.getElementById('search-input-mobile')?.focus();
        }
    };

    return (
        <StyledAccordion>
            <GeolocationHeader onClose={onClose} />
            <div className="input-wrapper">
                <SearchInput
                    placeholder="Поиск города или региона"
                    className="search-input"
                    value={searchText}
                    onChange={onTextValue}
                    id="search-input-mobile"
                />
                {debouncedSearch.length > 0 && foundGeolocation?.length > 0 && (
                    <GeolocationFoundList listSubject={foundGeolocation} onClick={setGeolocationFromSearch} />
                )}
            </div>
            {debouncedSearch.length > 0 && foundGeolocation?.length === 0 ? (
                <SearchNotFound changeQuery={handleClearInput} />
            ) : (
                <div className="accordion-content">
                    <div className="accordion" ref={ref}>
                        {newRegions.map((item) => {
                            const activeTab = tabs.find((elem) => elem.id === item.id);
                            const activeClassName = activeTab?.active ? ' active' : '';

                            return (
                                <AccordionItem key={item.id}>
                                    <input
                                        className="accordion-input"
                                        type="checkbox"
                                        name="accordion"
                                        id={`accordion_${item.id}`}
                                        onClick={() => handleChangeItemClick(item.id)}
                                        ref={item.id === geolocation?.parent?.id ? refRegion : null}
                                    />
                                    <label className="accordion-title" htmlFor={`accordion_${item.id}`}>
                                        <p>{item.name}</p>
                                        <div className={`triangle${activeClassName}`}>
                                            <ArrowDownIcon />
                                        </div>
                                    </label>
                                    <div className={`accordion-children${activeClassName}`}>
                                        <ul>
                                            {cities?.map((item) => {
                                                const activeCityClassName =
                                                    item.id === geolocation?.id ? 'activeCity' : '';

                                                return !loadingCities && cities ? (
                                                    <li
                                                        key={item.id}
                                                        onClick={() => setGeolocation(item)}
                                                        className={activeCityClassName}
                                                    >
                                                        {item.name}
                                                    </li>
                                                ) : null;
                                            })}
                                            {!loadingCities && <li onClick={setIsOtherCities}>Другие города</li>}
                                        </ul>
                                    </div>
                                </AccordionItem>
                            );
                        })}
                    </div>
                </div>
            )}
        </StyledAccordion>
    );
};
