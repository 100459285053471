import React, {
    ChangeEvent,
    FocusEventHandler,
    FocusEvent,
    KeyboardEvent,
    RefObject,
    ReactNode,
} from 'react';
import { FlattenInterpolation, ThemedStyledProps } from 'styled-components';
import * as Styled from './textbox.styles';
import { Themeinterface } from '../../../styles/themes/theme.type';
import { CrossIcon } from '../../svg-components/main/cross';

interface TextboxProps {
    reference?: RefObject<HTMLInputElement>;
    isTextArea?: boolean;
    placeholder?: string;
    className?: string;
    value?: string;
    onChange: (value: string) => void;
    onClearValue?: () => void;
    onKeyPress?: (value: number) => void;
    onFocus?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    autoFocus?: boolean;
    id?: string;
    beforeIcon?: ReactNode;
    maxLength?: number;
    disabled?: boolean;
    inputCss?: FlattenInterpolation<ThemedStyledProps<{ theme: Themeinterface }, unknown>>;
}

export const Textbox: React.FC<TextboxProps> = ({
    isTextArea,
    placeholder,
    className,
    value,
    onChange,
    onClearValue,
    onKeyPress,
    onFocus,
    autoFocus,
    id,
    reference,
    beforeIcon,
    maxLength,
    disabled,
    inputCss,
}) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(event.target.value);
    };

    const handleKeyPress = (event: KeyboardEvent<Element>) => {
        onKeyPress ? onKeyPress(event.charCode) : null;
    };

    const handleFocus = (data: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        onFocus ? onFocus(data) : null;
    };

    const onClearTextbox = () => {
        onClearValue && onClearValue();
    };

    return (
        <Styled.TextboxContainer>
            {beforeIcon && <Styled.BeforeIcon>{beforeIcon}</Styled.BeforeIcon>}
            {isTextArea && (
                <Styled.Textarea
                    value={value}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    onFocus={handleFocus}
                    placeholder={placeholder}
                    className={className}
                    maxLength={maxLength}
                    disabled={disabled}
                    inputCss={inputCss}
                />
            )}
            {!isTextArea && (
                <Styled.Textbox
                    as={'input'}
                    ref={reference}
                    value={value}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    onFocus={handleFocus}
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    className={className}
                    id={id}
                    maxLength={maxLength}
                    disabled={disabled}
                    inputCss={inputCss}
                />
            )}
            {!isTextArea && !!value && (
                <Styled.CrossWrapper>
                    <CrossIcon onClick={onClearTextbox} />
                </Styled.CrossWrapper>
            )}
        </Styled.TextboxContainer>
    );
};
