export enum IdTokenActionsEnum {
    setIdToken = 'SET_ID_TOKEN',
    setIsAuth = 'SET_IS_AUTH',
    setIdTokenIsExpired = 'SET_ID_TOKEN_IS_EXPIRED',
}

interface SetIdTokenInterface {
    type: typeof IdTokenActionsEnum.setIdToken;
    payload: string;
}

interface SetIsAuthInterface {
    type: typeof IdTokenActionsEnum.setIsAuth;
    payload: boolean;
}

interface SetIsTokenExpiredInterface {
    type: typeof IdTokenActionsEnum.setIdTokenIsExpired;
    payload: boolean;
}

export type IdTokenActions = SetIdTokenInterface | SetIsAuthInterface | SetIsTokenExpiredInterface;
