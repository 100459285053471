export interface UIState {
    feedback: {
        step: feedbackStepEnum;
    };
    geoModalOpened: boolean;
    isGlobalLoading: boolean;
    mainPageGroupLoading: number | null;
    searchResultCardLoading: number | null;
}

export enum UIActionsEnum {
    setFeedbackStep = 'SET_FEEDBACK_STEP',
    geoModalOpened = 'GEO_MODAL_OPENED',
    setIsGlobalLoading = 'SET_IS_GLOBAL_LOADING',
    setMainPageGroupLoading = 'SET_MAIN_PAGE_GROUP_LOADING',
    setSearchResultCardLoading = 'SET_SEARCH_RESULT_CARD_LOADING',
}

export enum feedbackStepEnum {
    firstQuestion,
    reasons,
    comment,
    success,
}

interface SetFeedbackStepAction {
    type: UIActionsEnum.setFeedbackStep;
    payload: feedbackStepEnum;
}

interface GeoModalOpened {
    type: UIActionsEnum.geoModalOpened;
    payload: boolean;
}

interface SetIsGlobalLoadingAction {
    type: UIActionsEnum.setIsGlobalLoading;
    payload: boolean;
}

interface setMainPageGroupLoading {
    type: UIActionsEnum.setMainPageGroupLoading;
    payload: number;
}
interface setSearchResultCardLoading {
    type: UIActionsEnum.setSearchResultCardLoading;
    payload: number;
}

export type UIActions =
    | SetFeedbackStepAction
    | GeoModalOpened
    | SetIsGlobalLoadingAction
    | setMainPageGroupLoading
    | setSearchResultCardLoading;
