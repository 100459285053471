import React, {SVGProps} from 'react';

export const CloseMobileIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.782 6.218a.743.743 0 0 0-1.051 0L12 10.948l-4.73-4.73a.743.743 0 1 0-1.052 1.051L10.948 12l-4.73 4.73a.743.743 0 0 0 1.051 1.052L12 13.052l4.731 4.73a.743.743 0 1 0 1.051-1.051L13.052 12l4.73-4.73a.743.743 0 0 0 0-1.052Z"
            fill="#969FA8"
        />
    </svg>
);