import { ChatActions, ChatActionsEnum, ChatState } from './chat-types';

const initialState = new ChatState();

const chatReducer = (state = initialState, action: ChatActions) => {
    switch (action.type) {
        case ChatActionsEnum.setModalTelephoneVisible:
            return {
                ...state,
                isVisibleModalTelephone: action.payload,
            };

        default:
            return state;
    }
};

export default chatReducer;
