import React, { useEffect, useState } from 'react';

import StyledFooter from './footer.styles';
import { useIsApp } from '../../../custom-hooks/use-is-app';
import Container from '../../layout/container';

const Footer: React.FC = () => {
    const [date, setDate] = useState<number>(0);
    const { isApp } = useIsApp();

    useEffect(() => {
        setDate(new Date().getFullYear());
    }, []);

    return (
        <StyledFooter id="footer-id">
            {!isApp && (
                <Container>
                    <div className="content">© {date} ПАО «МТС» 18+</div>
                </Container>
            )}
        </StyledFooter>
    );
};

export default Footer;
