export class Logo {
    constructor(
        public id: string | null = null,
        public name: string = '',
        public downloadLink: string | null = null,
        public showLink: string | null = null,
        public size: number | null = null,
        public createdAt: string | null = null,
    ) {}
}
