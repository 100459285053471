import { useEffect } from 'react';
import packageJson from '../../package.json';
import { isServer } from '../helpers/isServer';

export const useClearSiteData = () => {
    if (!isServer) {
        useEffect(() => {
            localStorage.removeItem('version');
            localStorage.setItem('version', packageJson.version);
        }, []);
    }
};
