import { Dispatch } from 'redux';
import { setCurrentUser } from './current-user-action';
import { CurrentUserModel } from '../../models/current-user.model';
import { getProfile } from '../../repositories/current-user.repository';
import { setIsAuth } from '../id-token/id-token-action';

export const getCurrentUserHttp = () => {
    return async (dispatch: Dispatch) => {
        const profile = await getProfile();

        if (profile) {
            const currentUser: CurrentUserModel = {
                id: profile['profile:guid'],
                name: profile['profile:name:org'],
                abonent: null,
                numberType: profile['profile:type'],
                accountType: profile['profile:type'],
                currentTariff: profile['mobile:tariff'],
                phone: profile['mobile:phone'],
                isAuth: 1,
            };

            dispatch(setCurrentUser(currentUser));
            dispatch(setIsAuth(true));
        } else {
            dispatch(setCurrentUser(new CurrentUserModel()));
            dispatch(setIsAuth(false));
        }
    };
};
