import { useEffect, useState } from 'react';
import { getEnv } from '../helpers/env.helper';
import { updEnv } from '../helpers/update-env';
import { requestEnvs } from '../repositories/envs.repository';

// хак создан ТОЛЬКО для страницы 404, на которой не работает getServerSideProps и нет другой возможности получить envs
export const useHeader404EnvsHack = () => {
    const [envsEnabled, setEnvsEnabled] = useState(false);

    useEffect(() => {
        if (getEnv(['BASE_URL'])) {
            setEnvsEnabled(true);
        } else {
            setTimeout(() => {
                if (!getEnv(['BASE_URL'])) {
                    requestEnvs()
                        .then(env => {
                            updEnv(env);
                            setEnvsEnabled(true);
                        });
                } else {
                    setEnvsEnabled(true);
                }
            }, 200);
        }
    }, []);

    return envsEnabled;
};
