/* eslint-env node */
module.exports = {
    SENTRY_ENVIRONMENT: 'MOCK',
    NEXT_PUBLIC_STAND: 'mock',
    NODE_TLS_REJECT_UNAUTHORIZED: '0',
    BASE_URL: 'https://mock.support.mts.ru',
    HEADER_ENV: 'stage',
    CHAT_ENV: 'mock',
    API_URLS: {
        NODE_SUPPORT: 'http://support-backend:8089/api/v1',
        SUPPORT: 'https://mock.support.mts.ru/api/v1',
    },
    WEBSOCKETS: {
        CHAT: 'wss://mock.online-chat.mts.ru/ws/chat',
    },
};
