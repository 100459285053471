import { color } from './color';
import { rem } from '../../helpers/rem-converter';

// @deprecated
export const border = {
    standard: '1px solid rgba(188, 195, 208, 0.5);',
    bold: '2px solid rgba(188, 195, 208, 0.5);',
    content: `1px solid ${color.primaryBorderColor}`,
};

// @deprecated
//@todo сделать переменные радиусов по размерам

export const borderRadius = {
    radius2: `${rem(2)}`,
    radius4: `${rem(4)}`,
    radius6: `${rem(6)}`,
    radius8: `${rem(8)}`,
    radius12: `${rem(12)}`,
    radius16: `${rem(16)}`,
    radius20: `${rem(20)}`,
    radius100: `${rem(100)}`,
    balloon: `0 ${rem(5)}`,
    round: '50%',
};
