import React from 'react';
import Head from 'next/head';

interface SeoForHeadProps {
    title: string;
    descriptionContent: string;
}

export const SeoForHead: React.FC<SeoForHeadProps> = ({ title, descriptionContent }) => {
    return (
        <Head>
            <title>{title}</title>
            <meta property="og:type" content="website" />
            <meta name="description" content={descriptionContent} />
        </Head>
    );
};
