import { IdTokenActionsEnum } from './id-token-types';

export const setIdToken = (idToken?: string) => ({
    type: IdTokenActionsEnum.setIdToken,
    payload: idToken,
});

export const setIsAuth = (isAuth: boolean) => ({
    type: IdTokenActionsEnum.setIsAuth,
    payload: isAuth,
});

export const setIsIdTokenExpired = (isExpired: boolean) => ({
    type: IdTokenActionsEnum.setIdTokenIsExpired,
    payload: isExpired,
});
