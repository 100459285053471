import styled, { css } from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { mobile, tablet } from '../../../styles/themes/media';
import { Themeinterface } from '../../../styles/themes/theme.type';
import Variables from '../../../styles/variables';
import Container from '../../layout/container';

const styledFont = css`
    font-size: ${rem(17)};
    line-height: ${rem(24)};

    ${tablet`
        font-size: ${rem(14)};
        line-height: ${rem(20)};
    `};

    ${mobile`
        font-size: ${rem(14)};
        line-height: ${rem(20)};
    `};
`;

const geoWrapStyles = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: static;
    max-width: unset;
    margin-right: 0;
    margin-top: 0;
    background-color: ${({ theme }) => theme.backgroundModal};
    box-shadow: none;
    border-radius: 0;
    padding: ${rem(20)} 0;
`;

const geoButtonStyles = css`
    background-color: ${({ theme }) => theme.controlTertiaryActive};
    color: ${({ theme }) => theme.textPrimary};
`;

const GeolocationConfirmContainer = styled(Container)`
    background-color: ${({ theme }) => theme.backgroundModal};
`;

const GeolocationConfirmWrap = styled.div<{ theme: Themeinterface }>`
    max-width: ${rem(377)};
    width: 100%;
    position: absolute;
    z-index: ${Variables.zIndexList.level5};
    padding: ${rem(24)};
    margin-top: ${rem(89)};
    background-color: ${({ theme }) => theme.backgroundModal};
    box-sizing: border-box;
    box-shadow: ${Variables.shadows.geolocation};
    border-radius: ${Variables.borderRadiusLg};

    ${tablet`
        ${geoWrapStyles};
    `};

    ${mobile`
        ${geoWrapStyles};
        flex-direction: column;
        align-items: flex-start;
    `};
`;

const GeolocationConfirmText = styled.div<{ theme: Themeinterface }>`
    ${styledFont};
    margin-bottom: ${rem(16)};
    color: ${({ theme }) => theme.textPrimary};

    ${tablet`
        margin-bottom: 0;
        width: 50%;
    `};

    ${mobile`
        width: 100%;
        margin-bottom: ${rem(20)};
    `};
`;

const GeolocationConfirmButtons = styled.div<{ theme: Themeinterface }>`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    ${mobile`
        width: 100%;
    `};

    button {
        padding: ${rem(10)} ${rem(24)};
        border-radius: ${Variables.borderRadiusMd};
        ${styledFont};

        ${tablet`
            padding: ${rem(6)} ${rem(16)};
        `};

        ${mobile`
            max-width: ${rem(156)};
            padding: ${rem(6)} ${rem(16)};
            flex: 1;
        `};
    }

    button:first-child {
        background-color: ${({ theme }) => theme.controlTertiaryActive};
        color: ${({ theme }) => theme.textPrimary};
        margin-right: ${rem(16)};

        ${tablet`
            ${geoButtonStyles};
        `};

        ${mobile`
            ${geoButtonStyles};
        `};
    }
`;

const GeolocationConfirmSpacer = styled.div`
    ${tablet`
        height: ${rem(72)};
    `};

    ${mobile`
        height: ${rem(112)};
    `};
`;

export { GeolocationConfirmWrap, GeolocationConfirmText, GeolocationConfirmButtons, GeolocationConfirmSpacer, GeolocationConfirmContainer };
