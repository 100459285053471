import api from './api/api';
import { egisApi, geolocationApi } from '../constants/api';
import { envService } from '../helpers/env.helper';
import { GeolocationData, GeolocationDataInterface, RegionData } from '../models/geolocation';

const getGeolocationData = async () => {
    const result = await api.get<GeolocationDataInterface>(geolocationApi);
    return result.data;
};

const getGeolocationByIp = async (ip: string) => {
    try {
        const result = await api.get<GeolocationData>(`${envService.getSupportApi()}/geolocation/ipsearch`, { ip });
        return result.data;
    } catch (e) {
        // todo: удалить заглушку сразу после выкатки этого реквеста (чтобы бек не сломал фронт, пока они не выкатят свои изменения)
        return Promise.resolve({
            id: 3051,
            name: 'Москва',
            parent: {
                id: 3045,
                name: 'Москва',
                parent: null,
            },
        });
    }
};

const getRegions = async (controller?: AbortController) => {
    const result = await api.get<RegionData>(`${envService.getSupportApi()}/geolocation/regions`, undefined, { signal: controller?.signal });
    return result.data;
};

const getCityForRegion = async (id: number) => {
    const result = await api.get<GeolocationData[]>(`${envService.getSupportApi()}/geolocation/${id}/children`);
    return result?.data;
};

const geolocationSearch = async (queryString: string) => {
    const url = `${envService.getSupportApi()}/geolocation/search?searchString=${queryString}`;
    const result = await api.get<GeolocationData[]>(url);
    return result.data;
};

const getCoordinates = async (queryString: string | null) => {
    const egisKey = process.env.API_KEYS.EGIS;
    const url = `${egisApi}/geocoder/geocode?yandex=true&authKey=${egisKey}&address=${queryString}`;
    const result = await api.get(url);
    return result.data;
};

const getObjects = async (bbox: string) => {
    const url = `${envService.getSupportApi()}/geolocation/egis?queryParam=typeName%3Dv_trade_points_rtk_old%26maxFeatures%3D100%26BBOX%3D${bbox}`;
    const result = await api.get(url);
    return result.data;
};

export {
    getGeolocationData,
    getRegions,
    getCityForRegion,
    geolocationSearch,
    getCoordinates,
    getObjects,
    getGeolocationByIp,
};
