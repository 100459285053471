import { useEffect } from 'react';

export const useNoScroll = (flag: boolean) => {
    useEffect(() => {
        if (flag && window) {
            window.document.body.style.overflow = 'hidden';
        } else {
            window.document.body.style.overflow = 'auto';
        }
    }, [flag]);
};
