import React, {FC, SVGProps} from 'react';

export const Telegram: FC = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg
        width={44}
        height={44}
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44 22c0 12.15-9.85 22-22 22S0 34.15 0 22 9.85 0 22 0s22 9.85 22 22ZM33.4 10.116a3.05 3.05 0 0 0-1.36.22L7.29 20.563c-.488.167-.929.45-1.284.825a.854.854 0 0 0 .398 1.385c1.343.454 2.691.89 4.043 1.317a6.89 6.89 0 0 0 2.835.439 2.703 2.703 0 0 0 1.258-.39c.543-.364 1.386-.934 2.375-1.602 3.101-2.097 7.631-5.159 8.768-5.872a5.04 5.04 0 0 1 1.433-.675c.331-.067.439.155.348.481a3.513 3.513 0 0 1-.806 1.249 275.283 275.283 0 0 1-6.777 6.483c-.44.366-.852.763-1.235 1.187a1.237 1.237 0 0 0 .001 1.686 2.1 2.1 0 0 0 .309.31c.995.821 2.052 1.56 3.12 2.28a604.56 604.56 0 0 0 4.45 2.968l.095.062c.75.525 1.59.904 2.48 1.12a1.673 1.673 0 0 0 2.09-1.097c.12-.314.203-.641.245-.975.04-.254.163-1.044.34-2.164.74-4.713 2.397-15.27 2.56-16.407.117-.618.174-1.246.172-1.875a1.154 1.154 0 0 0-1.108-1.182Z"
            fill="#08C"
        />
    </svg>
);