import React, { FC, ReactNode } from 'react';
import dynamic from 'next/dynamic';

const NoSSR: FC<{ children: ReactNode }> = (props) => {
    return <>{props.children}</>;
};

export default dynamic(() => Promise.resolve(NoSSR), {
    ssr: false,
});
