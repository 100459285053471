export const zIndex = {
    top: 99999,
    level5: 5,
    level4: 4,
    level3: 3,
    level2: 2,
    level1: 1,
    base: 0,
    bottom: -1,
    auto: 'auto',
};
