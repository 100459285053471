import axios from 'axios';
import { isServer } from './isServer';
import { logLevels } from '../models/types/logger.type';

// типизация https://confluence.mts.ru/pages/viewpage.action?pageId=837570278

//лог с бэка api
interface LogThisApiInterface {
    url?: string;
    method?: string;
    status?: number | string;
    userAgent?: string;
    error?: string;
    requestParams?: string;
}

//лог загрузки страницы
interface LogThisPageInterface {
    sefUrls?: {
        product?: string;
        section?: string;
        article?: string;
    };
}

//лог ошибки функции
interface LogThisFunctionInterface {
    error: string;
    context: unknown;
}

interface LogThisInterface {
    (loggedRequestUrl: string, params: LogItemInterface): void;
}

interface LogItemInterface {
    level: logLevels;
    label?: string;
    data: LogThisApiInterface | LogThisPageInterface | LogThisFunctionInterface;
}

export const logThis: LogThisInterface = async (
    loggedRequestUrl,
    {
        level,
        label,
        data,
    }
) => {
    const userAgent = isServer ? 'node' : window.navigator.userAgent;
    const logItem: LogItemInterface = {
        level,
        label,
        data: {
            url: loggedRequestUrl,
            userAgent,
            ...data,
        },
    };

    try {
        const logAddress = isServer ? `${process.env.PROTOCOL}://127.0.0.1:${process.env.PORT}` : '';
        axios.put(`${logAddress}/fe-api/logger`, logItem);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('log error', loggedRequestUrl, e);
    }
};
