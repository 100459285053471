import { AnyAction, combineReducers, Dispatch } from 'redux';
import articleReducer from './article/article-reducer';
import chatReducer from './chat/chat-reducer';
import { currentUserReducer } from './current-user/current-user-reducer';
import { idTokenReducer } from './id-token/id-token-reducer';
import segmentsReducer from './segments/segments-reducer';
import snackbarReducer from './snackbar/snackbar-reducer';
import uiReducer from './ui/ui-reducer';
import { StateModel } from '../models/state.model';

export const RESET_STORE_ACTION = 'RESET_STORE_ACTION';

// использовать каждый раз при использовании стора на стороне сервера
export const resetStore = {
    type: RESET_STORE_ACTION,
};

// использовать каждый раз, при использовании стора на сервере. Есть подозрения, что он не очищается сам
export const resetServerStore = () => {
    return ((dispatch: Dispatch) => {
        const oneHour = 3600000;
        const oneDay = oneHour * 24;
        setTimeout(() => dispatch(resetStore), oneDay);
        return {};
    }) as unknown as AnyAction;
};

const combinedReducer = combineReducers<StateModel>({
    article: articleReducer,
    segment: segmentsReducer,
    chat: chatReducer,
    snackbars: snackbarReducer,
    idToken: idTokenReducer,
    currentUser: currentUserReducer,
    ui: uiReducer,
});

const reducer = (_state: ReturnType<typeof combinedReducer>, action: AnyAction) => {
    let state: typeof _state | undefined = _state;

    if (action.type === RESET_STORE_ACTION) {
        state = undefined;
    }

    return combinedReducer(state, action);
};

export default reducer;
