import { ReactElement } from 'react';

export class Snackbars {
    list: Snackbar[] = [];
}

export enum SnackbarActionsEnum {
    openSnackbar = 'OPEN_SNACKBAR',
    closeSnackbar = 'CLOSE_SNACKBAR',
}

export type SnackbarTypes = 'info';

export type OpenSnackbarAction = {
    (props: {
        type: SnackbarTypes;
        content: ReactElement | string;
        action?: () => void;
        actionText?: string;
        duration?: number | 'infinite';
    }): OpenSnackbarActionInterface;
};

export type Snackbar = {
    id: number;
    type: SnackbarTypes;
    content: ReactElement | string;
    action?: () => void;
    actionText?: string;
    duration?: number;
};

export interface OpenSnackbarActionInterface {
    type: typeof SnackbarActionsEnum.openSnackbar;
    payload: Snackbar;
}

export interface CloseSnackbarActionInterface {
    type: typeof SnackbarActionsEnum.closeSnackbar;
    payload: {
        id: number;
    };
}

export type SnackbarActions = OpenSnackbarActionInterface | CloseSnackbarActionInterface;
