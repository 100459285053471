import { css } from 'styled-components';
import Variables from '../variables';

export const linkHover = css`
    &:hover {
        color: ${Variables.colors.darkBlueberryColor};
    }
`;

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const getVar = (name: string) => (props: any) => props[name];

export const fullWidthContainer = css`
    width: 100vw;
    position: relative;
    box-sizing: border-box;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
`;
