import React, {SVGProps} from 'react';

export const CloseChatIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg
        width={14}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.698 1.623A.76.76 0 0 0 1.623 2.7L5.925 7l-4.302 4.302a.76.76 0 0 0 1.075 1.076L7 8.076l4.302 4.302a.76.76 0 0 0 1.075-1.076L8.075 7 12.377 2.7a.76.76 0 1 0-1.075-1.076L7 5.925 2.698 1.623Z"
            fill="#fff"
        />
    </svg>
);