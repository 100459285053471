import React, { FC } from 'react';
// @ts-ignore
import App from '@support/mts-chat-react';
import { useSelector } from 'react-redux';
import { authUrls } from './chat-messenger.constants';
import { useIdToken } from '../../../custom-hooks/id-token.hook';
import { useIsApp } from '../../../custom-hooks/use-is-app';
import { productForChatFields, useProductForChatHook } from '../../../custom-hooks/use-product-for-chat.hook';
import { envService } from '../../../helpers/env.helper';
import { isServer } from '../../../helpers/isServer';
import { getItemFromLocalStorage } from '../../../helpers/local-storage.helper';
import { WidgetTypeEnum } from '../../../models/enums/chat-simple.enum';
import { selectCurrentProduct, selectWidgetChat } from '../../../redux/article/article-selectors';
import { selectIdToken } from '../../../redux/id-token/id-token-selectors';
import { ChatSimple } from '../../containers/chat-simple/chat-simple';
import * as StyledChat from '../../containers/chat-simple/chat-simple.styles';
import Container from '../../layout/container';
import NoSSR from '../../no-ssr';

export const ChatMessenger: FC<{ isDark: boolean }> = ({ isDark }) => {
    const productLocalStorage = !isServer && getItemFromLocalStorage(productForChatFields.product);
    const widgetChatSelector = useSelector(selectWidgetChat);
    const widgetChatLocalStorage = productLocalStorage && JSON.parse(productLocalStorage).widget;
    /*
    определение способа отображения чата:
    LocalStorage - если время предыдущей чат сессии не вышло
    StoreSelector - если первое посещение страницы с чатом
     */
    const widgetChat = widgetChatLocalStorage || widgetChatSelector;

    const idToken = useSelector(selectIdToken);
    const productSelector = useSelector(selectCurrentProduct);

    const isWidgetChatAvailable = widgetChat && widgetChat.showWidget && widgetChat.widgetType;

    const { isApp } = useIsApp();
    const { getIdToken } = useIdToken();
    const { productForChat, setProductForChat, clearProductForChat } = useProductForChatHook();

    const showChat = () => {
        switch (isWidgetChatAvailable) {
            case WidgetTypeEnum.messengerChat:
                return (
                    <ChatSimple messengerChannels={widgetChat.messengerChannels} />
                );
            case WidgetTypeEnum.supportChat:
                return (
                    <App
                        idToken={idToken}
                        onIdTokenIsInvalid={getIdToken}
                        productId={productForChat?.code || productSelector?.code}
                        product={productForChat?.name || productSelector?.name}
                        authUrls={authUrls}
                        env={envService.getChatStand()}
                        onDialogIsActive={setProductForChat}
                        onDialogIsClosed={clearProductForChat}
                        useChatDefaultDarkTheme={isDark}
                    />
                );
            default:
                return null;
        }
    };

    if (isServer || isApp) {
        return null;
    }

    return <NoSSR>
        <Container>
            <StyledChat.ChatWrapper>
                {showChat()}
            </StyledChat.ChatWrapper>
        </Container>
    </NoSSR>;
};
