const replacer = (firstLetter: string) => firstLetter.toUpperCase();
const anyWordRegex = /[а-яА-Я]|\w/;
const oneLetter = 1;

// 'hello world. some words' -> 'Hello world. Some words'
export const capitalize = (str: string) => {
    if (str === undefined || str === null) {
        return str;
    }
    if (str.length === oneLetter) {
        return str.toUpperCase();
    }
    const sentences = str.split(/\./gi);
    return sentences.map((sentence) => sentence.replace(anyWordRegex, replacer)).join('.');
};
