import {
    WebAnalyticsActionGroupEnum,
    WebAnalyticsEventActionEnum,
    WebAnalyticsEventCategoryEnum,
    WebAnalyticsEventContentEnum,
    WebAnalyticsEventEnum,
    WebAnalyticsEventLabelsEnum,
} from '../../models/enums/web-analytics.enum';
import { transliterate } from '../gtm.helper';
import { GtmService } from '../web-analytics.helpers';

export class SearchFiltersAnalytics extends GtmService {
    static openFiltersEvent() {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: WebAnalyticsEventLabelsEnum.filters,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
        });

        this.log(this.openFiltersEvent.name);
    }

    static startSearchRequestHome(productSectionName?: string) {
        const eventContent = productSectionName
            ? transliterate(productSectionName, true)
            : WebAnalyticsEventContentEnum.home;

        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction: WebAnalyticsEventActionEnum.confirmed,
            eventLabel: WebAnalyticsEventLabelsEnum.search,
            eventContent,
        });
        this.log(this.startSearchRequestHome.name);
    }

    static searchFieldFocus(productSectionName?: string) {
        const eventContent = productSectionName
            ? transliterate(productSectionName, true)
            : WebAnalyticsEventContentEnum.home;

        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: WebAnalyticsEventLabelsEnum.search,
            eventContent,
        });
        this.log(this.searchFieldFocus.name);
    }
}
