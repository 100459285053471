export const objectToBase64 = (data: unknown) => {
    if (!data) {
        return '';
    }

    const str = JSON.stringify(data);
    return btoa(
        // @ts-ignore
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode('0x' + p1))
    );
};

export const base64ToObject = <T = unknown>(dataBase64?: string): T | undefined => {
    if (!dataBase64) {
        return undefined;
    }

    const decodedString = decodeURIComponent(
        atob(dataBase64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );

    if (decodedString) {
        try {
            return JSON.parse(decodedString);
        } catch (e) {}
    }

    return undefined;
};
