import React, {SVGProps} from 'react';

export const CloseIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M15.61 2.276A1.333 1.333 0 0 0 13.724.391L8 6.114 2.276.391A1.333 1.333 0 1 0 .391 2.276L6.114 8 .391 13.724a1.333 1.333 0 1 0 1.885 1.886L8 9.886l5.724 5.724a1.333 1.333 0 1 0 1.886-1.886L9.886 8l5.724-5.724Z"
            fill="#fff"
        />
    </svg>
);