import { capitalize } from './capitalize';
import { WebAnalyticsNumberTypeEnum, WebAnalyticsPageTypeEnum, WebAnalyticsTouchPointEnum } from '../models/enums/web-analytics.enum';
import { Entries, Nullable } from '../models/types/common.type';
import { WebAnalyticsDataInterface } from '../models/web-analytics.model';

const maxLetters = 100;

export const transliterate = (text?: string | null, toLowerCase?: boolean) => {
    if (text === null || text === undefined) {
        return text;
    }

    const replacer = (match: string) => {
        const isUpper = match === match.toUpperCase();
        const enLetter = dictionary.get(match.toLowerCase()) ?? match;
        return isUpper ? capitalize(enLetter) : enLetter;
    };

    const replaced = text.replace(/[а-яА-ЯёЁ-]|\s/g, replacer).substring(0, maxLetters);
    return toLowerCase ? replaced.toLowerCase() : replaced;
};

const dictionary = new Map([
    ['а', 'a'],
    ['б', 'b'],
    ['в', 'v'],
    ['г', 'g'],
    ['д', 'd'],
    ['е', 'e'],
    ['ё', 'e'],
    ['ж', 'zh'],
    ['з', 'z'],
    ['и', 'i'],
    ['й', 'i'],
    ['к', 'k'],
    ['л', 'l'],
    ['м', 'm'],
    ['н', 'n'],
    ['о', 'o'],
    ['п', 'p'],
    ['р', 'r'],
    ['с', 's'],
    ['т', 't'],
    ['у', 'u'],
    ['ф', 'f'],
    ['х', 'h'],
    ['ц', 'c'],
    ['ч', 'ch'],
    ['ш', 'sh'],
    ['щ', 'sch'],
    ['ъ', ''],
    ['ы', 'y'],
    ['ь', ''],
    ['э', 'e'],
    ['ю', 'u'],
    ['я', 'ya'],
    [' ', '_'],
    ['-', '_'],
]);

export const pathFrom = (): WebAnalyticsPageTypeEnum => {
    let pageFrom;
    switch (window.location.pathname) {
        case '/':
            pageFrom = WebAnalyticsPageTypeEnum.main;
            break;
        case '/communicator':
        case '/mobile-id':
        case '/1click-payment':
        case '/cashback/info':
        case '/epay':
        case '/cashback/account-status/_cashback_request':
        default:
            pageFrom = WebAnalyticsPageTypeEnum.infoPage;
            break;
    }

    return pageFrom;
};

export const getNumberType = (type: string | null | undefined): WebAnalyticsNumberTypeEnum | null => {
    switch (type) {
        case 'МТС Коннект':
            return WebAnalyticsNumberTypeEnum.virtual;
        case 'Виртуальный номер':
            return WebAnalyticsNumberTypeEnum.other;
        case 'Мобильная связь':
            return WebAnalyticsNumberTypeEnum.real;
        default:
            return null;
    }
};

export const checkTouchPoint = (): WebAnalyticsTouchPointEnum => {
    // @ts-ignore
    const standalone = window.navigator.standalone,
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent);

    if (userAgent.includes('wv') || (ios && !standalone && !safari)) {
        // Android, iOS webview
        return WebAnalyticsTouchPointEnum.webview;
    }

    return WebAnalyticsTouchPointEnum.web;
};

export const undefinedToNull = (initialObject: WebAnalyticsDataInterface): Nullable<WebAnalyticsDataInterface> => {
    return (Object.entries(initialObject) as Entries<WebAnalyticsDataInterface>).reduce(
        (acc, curr) => {
            const key = curr![0];
            const value = curr![1];

            acc[key] = value === undefined ? null : value;
            return acc;
        },
        {} as Record<string, null | string | number>,
    ) as Nullable<WebAnalyticsDataInterface>;
};
