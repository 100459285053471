export class Article {
    constructor(
        public title: string = '',
        public content: string = '',
        public id: number = 0,
        public name: string = '',
        public sefUrl: string = '',
        public status: string = '',
        public lastModifiedAt: number = 0,
        public publishedAt: number = 0,
        public phoneRoaming: string = '',
        public phoneCountryInternal: string = '',
    ) {
    }
}
