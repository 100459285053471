import React, { ReactNode, useEffect } from 'react';
import { useIdToken } from '../custom-hooks/id-token.hook';
import { useTypedDispatch } from '../models/types/store.type';
import { getCurrentUserHttp } from '../redux/current-user/current-user-async-actions';

const Shell: React.FC<{ children: ReactNode }> = ({ children }) => {
    const dispatch = useTypedDispatch();
    useIdToken();

    useEffect(() => {
        dispatch(getCurrentUserHttp());
    }, []);

    return <>{children}</>;
};

export default Shell;
