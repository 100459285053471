import React, {SVGProps} from 'react';

export const CurrentGeoIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg
        width="10"
        height="9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M8.245.494A.889.889 0 0 1 9.37 1.62L7.11 8.394a.889.889 0 0 1-1.605.176L4.042 6.13a.889.889 0 0 0-.305-.305L1.293 4.358a.889.889 0 0 1 .176-1.605L8.245.494Z"
              fill="#969FA8"/>
    </svg>
);
