import styled from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { Themeinterface } from '../../../styles/themes/theme.type';

export const GeolocationCityWrap = styled.div<{ theme: Themeinterface }>`
    cursor: pointer;
    color: ${({ theme }) => theme.textSecondary};

    svg {
        margin-right: ${rem(4)};
    }
`;
