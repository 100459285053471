/* eslint-env node */
module.exports = {
    PORT: 3000,
    PROTOCOL: 'http',
    API_KEYS: {
        EGIS: 'IODxRhYcQwy9p0R9',
        GTM: 'GTM-KGQ8XWP',
        YANDEX_MAP: '39e14bfe-a781-4550-bc8d-fd89219833bb',
    },
    SC_DISABLE_SPEEDY: true,
    SENTRY_DSN: 'https://a78e659427e445ba8ab7ca99d4abb141@sentry.obs.mts.ru/782',
    SENTRY_DSN_MOB: 'https://a34120252a02445292ce23e049307d6a@sentry.obs.mts.ru/1062',
};
