import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { StyledCookiesContent, StyledCookiesHref } from './cookies-accept.styles';
import { useIsApp } from '../../../custom-hooks/use-is-app';
import { getCookie, setCookie } from '../../../helpers/cookies';
import { openSnackbarAction } from '../../../redux/snackbar/snackbar-actions';
import Variables from '../../../styles/variables';

export const CookiesAccept = () => {
    const dispatch = useDispatch();
    const { isApp } = useIsApp();

    const showDelay = 2500;

    useEffect(() => {
        const cookieAllow = getCookie('cookieAllow');

        if (!isApp && !cookieAllow) {
            // вначале ждем инициализации страницы, только потом спрашивает про cookie
            setTimeout(() => {
                dispatch(
                    openSnackbarAction({
                        type: 'info',
                        content: (
                            <div>
                                <StyledCookiesContent>
                                Продолжая пользоваться данным сайтом, вы соглашаетесь с
                                </StyledCookiesContent>
                                <StyledCookiesHref target="_blank" href="https://mts.ru/personal/cookie">
                                    {Variables.nbWhiteSpace}политикой обработки cookies
                                </StyledCookiesHref>
                            </div>
                        ),
                        action: () => setCookie('cookieAllow', 'true', undefined, true),
                        actionText: 'Oк',
                        duration: 'infinite',
                    }),
                );
            }, showDelay);
        }
    }, []);

    return null;
};
