import React from 'react';
import { modalTexts } from './geolocation-header.constants';
import { GeolocationHeaderStyles } from './geolocation-header.styles';
import { CloseMobileIcon } from '../../svg-components/main/close-mobile';

interface GeolocationHeaderProps {
    onClose: () => void;
}

export const GeolocationHeader: React.FC<GeolocationHeaderProps> = ({ onClose }) => {
    return (
        <GeolocationHeaderStyles>
            <div className="title-line">{modalTexts.title}</div>
            <CloseMobileIcon className="close" onClick={onClose} />
        </GeolocationHeaderStyles>
    );
};
