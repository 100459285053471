import React, { FC, useEffect } from 'react';
import * as Styled from './geolocation-confirm.styles';
import { GeoAnalytics } from '../../../helpers/web-analytics/geo-analytics';
import { WebAnalyticsActionGroupEnum, WebAnalyticsEventActionEnum, WebAnalyticsEventLabelsEnum } from '../../../models/enums/web-analytics.enum';
import Button from '../../form-controls/button/button';

interface PopupProps {
    onConfirm: () => void;
    cityName: string | null;
    onChangeRequest: () => void;
}

export const GeolocationConfirm: FC<PopupProps> = ({ onChangeRequest, cityName, onConfirm }) => {
    useEffect(() => {
        GeoAnalytics.geoEvent(WebAnalyticsEventActionEnum.elementShow, WebAnalyticsEventLabelsEnum.bannerGeo, WebAnalyticsActionGroupEnum.nonInteractions, cityName);
    }, []);

    const changeCity = () => {
        GeoAnalytics.geoEvent(WebAnalyticsEventActionEnum.buttonClick, WebAnalyticsEventLabelsEnum.no, WebAnalyticsActionGroupEnum.interactions, cityName);
        onChangeRequest();
    };

    return (
        <Styled.GeolocationConfirmWrap>
            <Styled.GeolocationConfirmText>
                Ваше местоположение{' '}
                <span>
                    <strong>{cityName}?</strong>
                </span>
            </Styled.GeolocationConfirmText>
            <Styled.GeolocationConfirmButtons>
                <Button onClick={changeCity}>Нет, изменить</Button>
                <Button onClick={onConfirm}>Да, спасибо</Button>
            </Styled.GeolocationConfirmButtons>
        </Styled.GeolocationConfirmWrap>
    );
};
