import api from './api/api';
import { envService } from '../helpers/env.helper';

interface Response {
    status: string;
}

interface ApiError {
    response: Response;
    error: string;
}

export const getIdTokenHttp = async () => {
    try {
        const result = await api.get<string>(`${envService.getSupportApi()}/users/id-token`);
        return result.data;
    } catch (err: unknown) {
        if (err && (err as ApiError).response && (err as ApiError).response.status !== '401') {
            // eslint-disable-next-line no-console
            console.log('id token fetch error');
        }
    }
};
