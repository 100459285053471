import { GetServerSidePropsContext } from 'next';
import { base64ToObject, objectToBase64 } from './base64';
import {
    appendToCookieString,
    getCookie,
    parseCookie,
    setCookie,
} from './cookies';
import { getIp } from './get-ip';
import { getContextCookieString } from './getContextCookieString';
import { isServer } from './isServer';
import { logThis } from './log-this';
import { geolocationCookie, requestGeolocationCookie } from '../constants/cookies';
import { GeolocationData } from '../models/geolocation';
import { logLevels } from '../models/types/logger.type';
import { getGeolocationByIp } from '../repositories/geolocation.repository';

const setGeoCookieHelper = (geolocation: GeolocationData) => setCookie(geolocationCookie, objectToBase64(geolocation), 7);
export const setRequestGeolocationCookie = (newState: boolean) => setCookie(requestGeolocationCookie, `${newState}`, 7);

// только для ноды
export const appendGeolocationSetCookie = async (ctx: GetServerSidePropsContext) => {
    if (isServer && ctx.req && ctx.res) {
        const cookies = parseCookie(ctx.req.headers.cookie);
        let geolocation = base64ToObject<GeolocationData>(cookies[geolocationCookie]);
        const requestGeolocation = true;
        const ip = getIp(ctx);
        let cookieString = getContextCookieString(ctx);

        // временное решение, только для кратковременного логирования
        logThis('', {
            level: logLevels.error,
            label: 'checkIp',
            data: {
                error: 'Проверка ip адреса',
                context: {
                    ip,
                    ['x-forwarded-for']: ctx.req.headers['x-forwarded-for'] as string,
                },
            },
        });

        if (!geolocation && ip) {
            try {
                geolocation = await getGeolocationByIp(ip);
                const geoCookie = setGeoCookieHelper(geolocation!);
                const requestGeoCookie = setRequestGeolocationCookie(requestGeolocation);
                const oldSetHeader = ctx.res!.getHeader('set-cookie') as string[] || [];
                ctx.res!.setHeader('set-cookie', [...oldSetHeader, geoCookie, requestGeoCookie]);

                cookieString = appendToCookieString(cookieString, geolocationCookie, objectToBase64(geolocation));
            } catch (e) {
                const geoCookie = setGeoCookieHelper(geolocation!);

                logThis('', {
                    level: logLevels.error,
                    label: 'appendGeolocationSetCookie',
                    data: {
                        error: 'Ошибка установки cookie геолокации на сервере',
                        context: {
                            ip,
                            geoCookie,
                            requestError: e.message,
                        },
                    },
                });
            }

        }

        return cookieString;
    }

    return '';
};

// для браузера
export const getGeolocationCookieData = () => base64ToObject<GeolocationData>(getCookie(geolocationCookie));

export const setGeolocationCookie = (newGeolocation: GeolocationData) => setGeoCookieHelper(newGeolocation);

export const setGeolocation = (newGeolocation: GeolocationData) => {
    setGeolocationCookie(newGeolocation);
    setRequestGeolocationCookie(false);
};

export const getRequestGeoCookie = () => JSON.parse(getCookie(requestGeolocationCookie) || 'false');
