import styled, { css } from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { mobile } from '../../../styles/themes/media';
import Variables from '../../../styles/variables';

const mobileStyles = css`
    left: 12px;
    width: calc(100vw - ${rem(24)});
`;

export const StyledSnackbarStack = styled.div`
    position: fixed;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    bottom: 0;
    left: 24px;
    right: 0;
    width: calc(100vw - ${rem(48)});
    z-index: ${Variables.zIndexList.top};

    ${mobile`${mobileStyles}`};
`;
