import { getUserPhoneId } from '../../helpers/get-user-phone-id';
import { StateModel } from '../../models/state.model';
import { selectCurrentProduct } from '../article/article-selectors';

export const selectCurrentUser = (state: StateModel) => state.currentUser;
export const selectUserId = (state: StateModel) => {
    const currentUser = selectCurrentUser(state);
    const currentProduct = selectCurrentProduct(state);
    return getUserPhoneId(String(currentUser?.phone), currentProduct?.code);
};
export const selectUserPhone = (state: StateModel) => state.currentUser.phone;
