import { snackbarDuration, snackbarDurationWithAction } from './constants';
import { CloseSnackbarActionInterface, OpenSnackbarAction, SnackbarActionsEnum } from './types';

let nextSnackbarId = 0;

const getDuration = (_duration: number | 'infinite' | undefined, hasAction: boolean) => {
    const durationProps = _duration === 'infinite' ? undefined : _duration;

    return _duration ? durationProps : hasAction ? snackbarDurationWithAction : snackbarDuration;
};

export const openSnackbarAction: OpenSnackbarAction = ({ type, content, action, actionText, duration: _duration }) => {
    const duration = getDuration(_duration, !!action);

    return {
        type: SnackbarActionsEnum.openSnackbar,
        payload: {
            id: nextSnackbarId++,
            type,
            content,
            action,
            actionText,
            duration,
        },
    };
};

export const closeSnackbarAction = (id: number): CloseSnackbarActionInterface => ({
    type: SnackbarActionsEnum.closeSnackbar,
    payload: {
        id,
    },
});
