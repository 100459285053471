import {
    WebAnalyticsActionGroupEnum,
    WebAnalyticsEventActionEnum,
    WebAnalyticsEventCategoryEnum,
    WebAnalyticsEventEnum,
    WebAnalyticsEventLabelsEnum,
} from '../../models/enums/web-analytics.enum';
import { GtmService } from '../web-analytics.helpers';

export class ABTestAnalytics extends GtmService {
    static userGroup(version: string, group: number ) {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntTest,
            eventCategory: WebAnalyticsEventCategoryEnum.test,
            eventAction: WebAnalyticsEventActionEnum.confirmed,
            eventLabel: WebAnalyticsEventLabelsEnum.abtest,
            eventContent: version,
            eventContext: group,
            actionGroup: WebAnalyticsActionGroupEnum.nonInteractions,
        });

        this.log(this.userGroup.name);
    }
}
