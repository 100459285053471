import React, { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { SearchNotFoundStyled } from './search-not-found.styles';

interface SearchNotFoundProps {
    changeQuery: () => void;
}

export const SearchNotFound: FC<SearchNotFoundProps> = ({ changeQuery }) => {
    const themeContext = useContext(ThemeContext);

    return (
        <SearchNotFoundStyled>
            <img className="image-found" src={themeContext.notFoundImage} alt="Ничего не нашли" />
            <div className="description-found">
                Ничего не найдено.
                <br />
                Попробуйте <a onClick={changeQuery}>изменить запрос</a>
            </div>
        </SearchNotFoundStyled>
    );
};
