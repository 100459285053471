import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { useIsApp } from '../../custom-hooks/use-is-app';
import { desktopHide, mobileHide } from '../../styles/themes/media';

const RootLayoutWrap = styled.div<{ isApp: boolean }>`
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: ${({ isApp }) => isApp ? 'flex-start' : 'space-between'};
    position: relative;
    overflow: hidden;

    .mobile-hidden {
        ${mobileHide};
    }

    .desktop-hidden {
        ${desktopHide};
    }
`;

const RootLayout: FC<{ children: ReactNode }> = ({ children }) => {
    const { isApp } = useIsApp();

    return (
        <RootLayoutWrap isApp={isApp}>
            {children}
        </RootLayoutWrap>
    );
};

export default RootLayout;
