import styled from 'styled-components';
import Variables from '../../../styles/variables';

export const StyledCookiesContent = styled.span`
    color: ${({ theme }) => theme.textInverted};
`;

export const StyledCookiesHref = styled.a`
    color: ${({ theme }) => theme.textPrimaryLinkInverted};
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: ${Variables.colors.normalBlueberryColor};
    }
`;
