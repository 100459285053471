import { Themeinterface } from './theme.type';
import errorPageImage from '../../images/error-page-dark.png';
import notFoundDarkImage from '../../images/not-found-dark.png';
import { themeEnum } from '../../models/enums/theme.enum';
import { color } from '../tokens/color';
import { colorConst } from '../tokens/new/color-const';
import Variables from '../variables';

export const darkTheme: Themeinterface = {
    themeName: themeEnum.dark,
    brand: colorConst.torchRed,
    accentActive: colorConst.blueberryLight,
    accentPositive: colorConst.appleLight,
    accentWarning: colorConst.marigoldYellow,
    accentNegative: colorConst.salmon,
    accentActiveInverted: colorConst.blueberryDark,
    accentPositiveInverted: colorConst.appleNormal,
    accentWarningInverted: colorConst.bananaDark,
    accentNegativeInverted: colorConst.orangeNormal,
    textHeadline: colorConst.greyscale0,
    textPrimary: colorConst.alabaster,
    textSecondary: colorConst.greyscale400,
    textTertiary: colorConst.greyscale500,
    textInverted: colorConst.greyscale800,
    textPositive: colorConst.appleLight,
    textNegative: colorConst.orangeLight,
    textPrimaryLink: colorConst.blueberryLight,
    textPrimaryLinkHover: colorConst.blueberryLight,
    textPrimaryLinkVisited: colorConst.blueberryDark,
    textPrimaryLinkInverted: colorConst.blueberryDark,
    textSecondaryLink: colorConst.greyscale400,
    textSecondaryLinkHover: colorConst.greyscale500,
    textSecondaryLinkVisited: colorConst.greyscale500,
    textVisitedLink: colorConst.fuchsiaPink,
    textInactiveTabBar: colorConst.white46,
    backgroundPrimary: colorConst.greyscale900,
    backgroundStroke: colorConst.regentGray35,
    backgroundPrimaryElevated: colorConst.greyscale800,
    backgroundSecondary: colorConst.mirageBlue25,
    backgroundSecondaryElevated: colorConst.mirageBlue25,
    backgroundModal: colorConst.greyscale700,
    backgroundInverted: colorConst.greyscale0,
    backgroundOverlay: colorConst.cinder60,
    backgroundHover: colorConst.solitude20,
    backgroundHoverInverted: colorConst.linkWater25,
    backgroundNavbarIos: colorConst.black92,
    backgroundDisabled: colorConst.mirageBlue15,
    backgroundStrokeDisabled: colorConst.regentGray10,
    backgroundLower: colorConst.greyscale900,
    controlPrimaryActive: colorConst.torchRed,
    controlSecondaryActive: colorConst.greyscale0,
    controlTertiaryActive: colorConst.regentGray35,
    controlInactive: colorConst.regentGray35,
    controlAlternative: colorConst.regentGray35,
    controlAlternativeHover: colorConst.linkWater40,
    controlAlternativeStrokeHover: colorConst.nevada,
    controlActiveTabBar: colorConst.greyscale0,
    controlInactiveTabBar: colorConst.white46,
    controlStroke: colorConst.catPerson,
    iconPrimary: colorConst.alabaster,
    iconSecondary: colorConst.greyscale400,
    iconTertiary: colorConst.greyscale500,
    premium: 'linear-gradient(48deg, #9BD5FF 6.25%, #9B93FF 44.27%, #9A8DFF 58.33%, #7C86FE 84.9%)',
    blendHover: 'normal',
    blendHoverInverted: 'multiply',
    border: colorConst.greyscale900,
    loaderBackground: color.loaderBackgroundDark,
    loaderColor: 2,
    imageOpacity: 0.88,
    backgroundOverlayModal: colorConst.backgroundOverlayDark,
    sidebarContainerHide: Variables.sidebarContainerHide.dark,
    contentStroke: colorConst.transparent,
    notFoundImage: notFoundDarkImage,
    footerBackground: colorConst.outerSpace,
    errorPageImage: errorPageImage,
};
