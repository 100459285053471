export const title = 'МТС Поддержка';
export const titleProductGroup = ' - Портфель продуктов';
export const titleProduct = ' - Продукты';
export const titleMobile = ' - Mobile';
export const titleSearch = 'Поиск';
export const titleArticle = ' - Статья';
export const contentSupport = 'Помощь, инструкции и ответы на часто задаваемые вопросы от службы поддержки МТС';
export const contentProductGroup =
    'Помощь, инструкции и ответы на часто задаваемые вопросы от службы поддержки МТС';
export const contentProduct = '. Помощь, инструкции и ответы на часто задаваемые вопросы от службы поддержки МТС';
export const contentMobile = 'Mobile. Помощь, инструкции и ответы на часто задаваемые вопросы от службы поддержки МТС';
export const contentSearch = 'Поиск. Помощь, инструкции и ответы на часто задаваемые вопросы от службы поддержки МТС';
export const contentArticle = 'Помощь, инструкции и ответы на часто задаваемые вопросы от службы поддержки МТС';
export const itemSchemaService = 'https://schema.org/Service';
export const itemSchemaArticle = 'https://schema.org/Article';
export const itemSchemaSearchAction = 'https://schema.org/SearchAction';
export const itemSchemaBreadcrumbList = 'https://schema.org/BreadcrumbList';
export const itemSchemaFAQPage = 'https://schema.org/FAQPage';
export const itemSchemaQuestion = 'https://schema.org/Question';
export const itemSchemaAnswer = 'https://schema.org/Answer';
export const itemSchemaListItem = 'https://schema.org/ListItem';
