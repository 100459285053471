import { UAParser } from 'ua-parser-js';

export namespace deviceDetectHelper {
    export const isMobile = (userAgent: string) => {
        const uaParser = new UAParser(userAgent);
        return uaParser.getDevice().type === 'mobile';
    };

    export const isTablet = (userAgent: string) => {
        const uaParser = new UAParser(userAgent);
        return uaParser.getDevice().type === 'tablet';
    };

    export const isDesktop = (userAgent: string) => {
        const uaParser = new UAParser(userAgent);
        return uaParser.getDevice().type !== 'mobile' && uaParser.getDevice().type !== 'tablet';
    };
}
