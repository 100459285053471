import { css } from 'styled-components';
import { rem } from '../../helpers/rem-converter';

export const scrollbar = css`
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.controlInactiveTabBar} transparent;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: ${rem(5)};

        &-thumb {
            border: ${rem(4)} solid ${({ theme }) => theme.controlInactiveTabBar};
            border-radius: ${rem(8)};
        }
    }
`;
