import React from 'react';
import { useDispatch } from 'react-redux';
import { StyledContent, StyledTelephoneModal } from './telephone-modal.styles';
import { telephoneNumberFormat } from '../../../helpers/format-number.helper';
import { setModalVisible } from '../../../redux/chat/chat-actions';
import { CloseIcon } from '../../svg-components/main/close';

interface TelephoneModalProps {
    isOpen: boolean;
    telephoneNumber?: string;
}

export const TelephoneModal: React.FC<TelephoneModalProps> = ({ isOpen, telephoneNumber }) => {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setModalVisible(false));
    };

    return (
        <>
            {isOpen ? (
                <StyledTelephoneModal>
                    <div className="wrapper-content">
                        <StyledContent>
                            <div className="text">Круглосуточно</div>
                            {telephoneNumber && (
                                <div className="telephone-number">{`8 ${telephoneNumberFormat(telephoneNumber)}`}</div>
                            )}
                        </StyledContent>
                        <CloseIcon onClick={handleClose} className="close" />
                    </div>
                </StyledTelephoneModal>
            ) : null}
        </>
    );
};
