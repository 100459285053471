import { useSelector } from 'react-redux';
import * as Styled from './global-loader.styles';
import { selectIsGlobalLoading } from '../../../redux/ui/ui-selectors';
import { LoaderCircle, loaderSizes, loaderTypes } from '../loader-circle/loader-circle';

export const GlobalLoader = () => {
    const isActive = useSelector(selectIsGlobalLoading);

    if (!isActive) {
        return null;
    }

    return (
        <Styled.Wrapper isActive={isActive}>
            <LoaderCircle centered type={loaderTypes.dark} size={loaderSizes.large} />
        </Styled.Wrapper>
    );
};
