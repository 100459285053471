import { Article } from './article';

export class Section {
    constructor(
        public id: number | null = null,
        public name: string = '',
        public code: string = '',
        public sefUrl: string = '',
        public topArticle: Article = new Article(),
        public parentId: number | null = null,
        public children: Section[] = [],
        public position: number | null = null,
        public status: string = '',
        public articles: Article[] = [],
        public totalArticles: number = 0,
    ) {
    }
}
