import { SegmentApiNameEnum } from '../../../models/enums/segment-api-name.enum';
import { SegmentsEnum } from '../../../models/enums/segments.enum';

export const getParentSegmentUrl = (parentSegmentCode: SegmentApiNameEnum | string) => {
    switch (parentSegmentCode) {
        case SegmentsEnum.partners: return `/${SegmentsEnum.partners}`;
        case SegmentsEnum.business: return `/${SegmentsEnum.business}`;

        default:
            return '/';
    }
};

export const isAuth = 'IS_AUTH';

export const removeIsAuth = () => {
    localStorage?.removeItem(isAuth);
};

export const setIsAuth = () => {
    localStorage?.setItem(isAuth, '1');
};
