import { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { rem } from '../../helpers/rem-converter';
import {
    desktopL,
    desktopS,
    desktopXS,
    media,
    mobile,
    tablet,
} from '../../styles/themes/media';

export const sidePaddingsDesktop = 56;
export const sidePaddingsDesktopS = 48;
export const sidePaddingsDesktopXS = 40;
export const sidePaddingsTablet = 40;
export const sidePaddingsMobile = 20;

export const contextWidth = 1168;

export const StyledOuterContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 ${rem(sidePaddingsDesktop)};

    ${desktopS`padding: 0 ${rem(sidePaddingsDesktopS)}`};
    ${desktopXS`padding: 0 ${rem(sidePaddingsDesktopXS)}`};
    ${tablet`padding: 0 ${rem(sidePaddingsTablet)}`};
    ${mobile`padding: 0 ${rem(sidePaddingsMobile)}`};
`;

export const StyledInnerContainer = styled.div<{ size: ContainerSizeType }>`
    width: 100%;
    max-width: ${rem(1168)};

    ${({ size }) => size === 'small' && css`
        ${desktopXS`
            max-width: ${rem(728)};
        `};

        ${media(desktopS, desktopL)`
            max-width: ${rem(768)};
        `};
    `};
`;

interface ContainerProps {
    children: ReactNode;
    className?: string;
    size?: ContainerSizeType;
}

type ContainerSizeType = 'normal' | 'small';

const Container: FC<ContainerProps> = ({ children, className, size = 'normal' }) => (
    <StyledOuterContainer className={className}>
        <StyledInnerContainer size={size}>{children}</StyledInnerContainer>
    </StyledOuterContainer>
);

export default Container;
