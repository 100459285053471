import {
    getGeolocationData,
    getRegions,
    getCityForRegion,
    geolocationSearch,
    getCoordinates,
    getObjects,
    getGeolocationByIp,
} from '../repositories/geolocation.repository';

export const GeolocationService = {
    getGeolocationData,
    getRegions,
    getCityForRegion,
    geolocationSearch,
    getCoordinates,
    getObjects,
    getGeolocationByIp,
};
