import styled, { css } from 'styled-components';
import { color } from '../../../styles/tokens/color';
import { zIndex } from '../../../styles/tokens/zIndex';

export const Wrapper = styled.div<{ isActive: boolean }>`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    z-index: ${zIndex.top};

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border-radius: inherit;
        transition: all 0.2s ease-in;
        pointer-events: none;
        background-color: transparent;
        z-index: ${zIndex.level2};
    }

    ${({ isActive }) =>
        isActive &&
        css`
            cursor: default;
            pointer-events: none;
            user-select: none;

            &:after {
                content: '';
                background-color: ${color.loaderBackground};
                pointer-events: auto;
            }
        `};
`;
