import { NavItemInterface } from './header.types';
import { envService } from '../../../helpers/env.helper';

export const loginPath = 'oauth2/authorization/mts-web-sso';

export type LogInOutEndpointsType = Record<string, Record<string, {
    base: string;
    endpoints: {
        login: string;
        logout: string;
    };
}>>;

export const getLogInOutEndpoints = (): LogInOutEndpointsType => {
    return {
        // @ts-ignore
        [envService.getHeaderStand()]: {
            profile: {
                get base(): string {
                    return envService.getBaseUrl() as string;
                },
                endpoints: {
                    login: loginPath,
                    logout: '/logout',
                },
            },
        },
    };
};

export const navItems: NavItemInterface[] = [
    {
        href: '/',
        label: 'Справка',
        navigate: true,
    },
    /*
    {
        href: '/appeals-center',
        label: 'Центр обращений',
        navigate: false
    }
     */
];
