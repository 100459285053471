import styled, { css, keyframes } from 'styled-components';
import LoaderIcon from '../../../images/icons/loader-circle.svg';
import { getVar } from '../../../styles/shared-css/helpers';
import { zIndex } from '../../../styles/tokens/zIndex';

const spinnerAnimation = ({ scale }: { scale: number }) => keyframes`
    from {
        transform: scale(${scale}) rotate(0deg);
    }
    to {
        transform: scale(${scale}) rotate(360deg);
    }
`;

export const Wrapper = styled.span<{ centered?: boolean }>`
    ${({ centered }) =>
        centered &&
        css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            line-height: 0;
            z-index: ${zIndex.level3};
        `}
`;

// @ts-ignore
export const Loader = styled(LoaderIcon)<{ color: string; scale: number }>`
    animation: ${(props) => spinnerAnimation(props)} 1s linear infinite;
    transform-origin: center;
    will-change: transform;
    *:not(mask) path {
        fill: ${getVar('color')};
    }
`;
