// получать через ref не лучшая идея, потому что придется мутировать переменную. Заранее известная константа лучше
import { isServer } from '../helpers/isServer';

export const defaultHeaderHeight = 52;
export const segmentsLineHeight = 32;

export const headerHeight = (() => {
    if (isServer) {
        return defaultHeaderHeight;
    } else {
        const headerContent: HTMLElement | null = document.querySelector('#header-container');
        return headerContent?.offsetHeight || defaultHeaderHeight;
    }
})();
