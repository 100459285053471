import { SegmentNamesEnum } from '../../../models/enums/segment-names.enum';
import { SegmentApiNameEnum } from '../../../models/enums/segment-api-name.enum';

export const menuItems = [
    {
        title: SegmentNamesEnum.individuals,
        titleMobile: 'Частным',
        href: '/',
        code: SegmentApiNameEnum.individuals,
    },
    {
        title: SegmentNamesEnum.business,
        titleMobile: 'Бизнесу',
        href: '/business',
        code: SegmentApiNameEnum.business,
    },
    {
        title: SegmentNamesEnum.partners,
        titleMobile: 'Партнерам',
        href: '/partners',
        code: SegmentApiNameEnum.partners,
    },
];

export const segmentsMenuLinks = menuItems.map(i => i.href);
