export const getItemFromLocalStorage = (key: string): string | null => {
    return localStorage?.getItem ? localStorage.getItem(key) : null;
};

export const setItemToLocalStorage = (key: string, value: string): void => {
    if (localStorage?.setItem) {
        localStorage.setItem(key, value);
    }
};

export const removeItemFromLocalStorage = (key: string): void => {
    if (localStorage?.removeItem) {
        localStorage.removeItem(key);
    }
};
