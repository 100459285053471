import { CurrentUserAction } from './current-user-types';
import { CurrentUserModel } from '../../models/current-user.model';
import { CurrentUserActionsEnum } from '../../models/enums/current-user-actions.enum';

const initialState = new CurrentUserModel();

export const currentUserReducer = (state = initialState, action: CurrentUserAction) => {
    switch (action.type) {
        case CurrentUserActionsEnum.setCurrentUser:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
