import { createWrapper } from 'next-redux-wrapper';
import { createStore, applyMiddleware, Middleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './root-reducer';
import { isProd } from '../helpers/env.helper';

const middleware = [thunkMiddleware];

const bindMiddleware = (middleware: Middleware[]) => {
    if (!isProd()) {
        return composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

const store = createStore(
    // @ts-ignore
    rootReducer,
    bindMiddleware(middleware),
);

const makeStore = () => store;

export const nextWrapper = createWrapper(makeStore, {
    serializeState: (state) => JSON.stringify(state),
    deserializeState: (state) => JSON.parse(state),
    debug: false,
});

export default store;
