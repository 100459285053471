// eslint-disable-next-line
type CacheRecord = Record<string, any[] | undefined>;
type ItemStoreName = string;
type StoreType = { cache: Record<string, CacheRecord | undefined> };

// мерж старых полей и новых, с удалением старых
export const getUpdatedState = <S = StoreType>(payload: CacheRecord, oldStoreState: S, name: ItemStoreName): S => {
    const rootIds = Object.keys(payload);
    const newState: CacheRecord = {};
    const oldCache: CacheRecord = (oldStoreState as StoreType).cache[name] as CacheRecord;

    rootIds.forEach((id) => {
        const updatedItems = payload[id] || [];
        const updatedArticlesIds = updatedItems.map((item) => item.id);
        const cacheWithoutNew =
            oldCache[id]?.filter((cacheSubject) => !updatedArticlesIds.includes(cacheSubject.id)) || [];
        newState[id] = [...cacheWithoutNew, ...updatedItems];
    });

    return {
        ...oldStoreState,
        cache: {
            ...(oldStoreState as StoreType).cache,
            [name]: {
                ...oldCache,
                ...newState,
            },
        },
    } as S;
};

export const getSetCacheState = <S>(payload: CacheRecord, oldStoreState: S, name: ItemStoreName) => {
    const store = oldStoreState as StoreType;
    return {
        ...store,
        cache: {
            ...store.cache,
            [name]: {
                ...store.cache[name],
                ...payload,
            },
        },
    } as S;
};

export const getDeleteCacheState = <S>(payload: string[], oldStoreState: S, name: ItemStoreName) => {
    const store = oldStoreState as StoreType;
    const oldCache: CacheRecord = (oldStoreState as StoreType).cache[name] as CacheRecord;
    const newCache = { ...oldCache };
    for (const key of payload) {
        delete newCache[key];
    }

    return {
        ...store,
        cache: {
            ...store.cache,
            [name]: newCache,
        },
    } as S;
};
