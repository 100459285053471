import { ChannelTypeEnum, WidgetTypeEnum } from './enums/chat-simple.enum';
import { SegmentB2bB2cEnum } from './enums/widget.enum';

export interface WidgetChatInterface {
    segment: SegmentB2bB2cEnum;
    showWidget: boolean;
    widgetType: WidgetTypeEnum;
    messengerChannels: Array<MessengerChannelInterface>;
}

export interface MessengerChannelInterface {
    activeLink: boolean;
    label: string;
    link: string;
    placeholder: string;
    showChannel: boolean;
    type: ChannelTypeEnum;
}

class MessengerChannel {
    constructor(
        public activeLink: boolean = false,
        public label: string = '',
        public link: string = '',
        public placeholder: string = '',
        public showChannel: boolean = false,
        public type: ChannelTypeEnum.telegram,
    ) {}
}

export class WidgetChat {
    constructor(
        public segment: SegmentB2bB2cEnum = SegmentB2bB2cEnum.b2c,
        public showWidget: boolean = false,
        public widgetType: WidgetTypeEnum = WidgetTypeEnum.messengerChat,
        public messengerChannels: MessengerChannel[] = [],
    ) {}
}
