import styled, { css } from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import {
    desktopHide,
    media,
    mediaHide,
    mobile,
    tablet,
} from '../../../styles/themes/media';
import { Themeinterface } from '../../../styles/themes/theme.type';
import { color } from '../../../styles/tokens/color';
import Variables from '../../../styles/variables';

export const Menu = styled.div`
    display: flex;
    align-items: center;
`;

export const MobileMenuWrap = styled.div`
    ${mediaHide(desktopHide)};
`;

export const TabItem = styled.div<{ isActive?: boolean; theme: Themeinterface }>`
    margin-right: ${rem(24)};

    &:last-child {
        margin-right: 0;
    }

    a {
        cursor: pointer;
        text-decoration: none;
        font-family: ${Variables.fonts.fontMedium};
        color: ${({ theme }) => theme.textSecondary};

        ${p => p.isActive && css`
            color: ${color.loaderPrimary};
            pointer-events: none;
        `};

        &:hover {
            color: ${color.loaderPrimary};
        }
    }

    ${media(tablet, mobile)`
        margin-right: ${rem(16)};

        a {
            position: relative;
            display: block;
            box-sizing: border-box;
            font-size: ${rem(14)};
            line-height: ${rem(20)};
            padding: 6px 0;
            color: ${({ theme }) => theme.textSecondary};

            &:hover {
                color: ${color.textLightSecondary};
            }

            &::after {
                position: absolute;
                bottom: 0;
                content: "";
                display: block;
                height: ${rem(2)};
                width: 100%;
                border-radius: ${rem(10)};
                background-color: transparent;
            }

            ${p => p.isActive && css`
                color: ${({ theme }) => theme.textPrimary};

                &::after {
                    background-color: ${color.loaderPrimary};
                }
            `};
        }
    `};
`;

export const TopControlsSpacer = styled.div`
    height: ${rem(32)};
`;
