import {
    feedbackStepEnum,
    UIActions,
    UIActionsEnum,
    UIState,
} from './ui-types';

const initialState: UIState = {
    feedback: {
        step: feedbackStepEnum.firstQuestion,
    },
    geoModalOpened: false,
    isGlobalLoading: false,
    mainPageGroupLoading: null,
    searchResultCardLoading: null,
};

const uiReducer = (state = initialState, action: UIActions) => {
    switch (action.type) {
        case UIActionsEnum.setFeedbackStep:
            return {
                ...state,
                feedback: {
                    ...state.feedback,
                    step: action.payload,
                },
            };
        case UIActionsEnum.geoModalOpened:
            return {
                ...state,
                geoModalOpened: action.payload,
            };
        case UIActionsEnum.setIsGlobalLoading:
            return {
                ...state,
                isGlobalLoading: action.payload,
            };
        case UIActionsEnum.setMainPageGroupLoading:
            return {
                ...state,
                mainPageGroupLoading: action.payload,
            };
        case UIActionsEnum.setSearchResultCardLoading:
            return {
                ...state,
                searchResultCardLoading: action.payload,
            };
        default:
            return state;
    }
};

export default uiReducer;
