import { css } from 'styled-components';
import { screenResolutions } from '../variables';

export const displayNone = css`
    display: none;
`;

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type MixinHideName = `${keyof typeof screenResolutions}Hide`;
type CssParams<T extends object> = Parameters<typeof css<T>>;
type MediaFnType = <T extends object>(...params: CssParams<T>) => ReturnType<typeof css<T>>;

type MixinsType = Record<keyof typeof screenResolutions, MediaFnType>;
type MixinsHideType = Record<MixinHideName, ReturnType<MediaFnType>>;

const mixins = {} as MixinsType;
const hideMixins = {} as MixinsHideType;

const allResolutionsNames = Object.keys(screenResolutions) as Array<keyof typeof screenResolutions>;
allResolutionsNames.forEach((key) => {
    mixins[key] = <T extends object>(...args: CssParams<T>) => {
        const resolutionData = screenResolutions[key];
        let resolution = '';
        resolution += resolutionData.min ? `and (min-width: ${resolutionData.min}px) ` : '';
        resolution += resolutionData.max ? `and (max-width: ${resolutionData.max}px) ` : '';
        resolution = resolution.trim();

        return css`
            @media only screen ${resolution} {
                ${css(...args)}
            }
        `;
    };

    hideMixins[`${key}Hide` as MixinHideName] = mixins[key]`${displayNone}`;
});

export const media: (...params: MediaFnType[]) => MediaFnType =
    (...mediaFunctions) =>
        (styles, ...params) =>
            mediaFunctions.map((fn) => fn(styles, ...params));
export const mediaHide = (...mediaFunctions: ReturnType<MediaFnType>) => mediaFunctions;

export const desktop = mixins.desktop;
export const desktopHide = hideMixins.desktopHide;

export const desktopL = mixins.desktopL;
export const desktopLHide = hideMixins.desktopLHide;

export const desktopS = mixins.desktopS;
export const desktopSHide = hideMixins.desktopSHide;

export const desktopXS = mixins.desktopXS;
export const desktopXSHide = hideMixins.desktopXSHide;

export const tablet = mixins.tablet;
export const tabletHide = hideMixins.tabletHide;

export const mobile = mixins.mobile;
export const mobileHide = hideMixins.mobileHide;
