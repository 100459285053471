import React from 'react';
import { Telegram } from '../components/svg-components/chat/telegram';
import { TelephoneIcon } from '../components/svg-components/chat/telephone';
import { Whatsapp } from '../components/svg-components/chat/whatsapp';
import { ChannelTypeEnum } from '../models/enums/chat-simple.enum';
import { WebAnalyticsEventLabelsEnum } from '../models/enums/web-analytics.enum';

export const getTypeChannel = (channelType: ChannelTypeEnum) => {
    switch (channelType) {
        case ChannelTypeEnum.whatsapp:
            return <Whatsapp />;
        case ChannelTypeEnum.telegram:
            return <Telegram />;
        case ChannelTypeEnum.phone:
            return <TelephoneIcon />;
    }
};

export const getTypeWebAnalytics = (channelType: ChannelTypeEnum) => {
    switch (channelType) {
        case ChannelTypeEnum.whatsapp:
            return WebAnalyticsEventLabelsEnum.whatsapp;
        case ChannelTypeEnum.telegram:
            return WebAnalyticsEventLabelsEnum.telegram;
        case ChannelTypeEnum.phone:
            return WebAnalyticsEventLabelsEnum.phone;
        default:
            return '';
    }
};

export const getTypeBlank = (channelType: ChannelTypeEnum) => {
    return channelType === ChannelTypeEnum.whatsapp || channelType === ChannelTypeEnum.telegram ? '_blank' : '_self';
};
