import React from 'react';
import { useSelector } from 'react-redux';
import { menuItems } from './segments-menu.constants';
import * as Styled from './segments-menu.styles';
import { SegmentApiNameEnum } from '../../../models/enums/segment-api-name.enum';
import { selectCurrentSegmentCode } from '../../../redux/segments/segments-selectors';

export interface SegmentsMenuProps {
    isMobile?: boolean;
}

export const SegmentsMenu: React.FC<SegmentsMenuProps> = ({ isMobile = false }) => {
    const segmentCode = useSelector(selectCurrentSegmentCode);

    return (
        <Styled.Menu>
            {menuItems.map(segment => (
                <Styled.TabItem key={segment.code} isActive={SegmentApiNameEnum[segmentCode] === segment.code}>
                    <a href={segment.href}>{isMobile ? segment.titleMobile : segment.title}</a>
                </Styled.TabItem>
            ))}
        </Styled.Menu>
    );
};
