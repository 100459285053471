import { IdTokenActions, IdTokenActionsEnum } from './id-token-types';
import { IdTokenModel } from '../../models/id-token.model';

const initialState = new IdTokenModel();

export const idTokenReducer = (state = initialState, action: IdTokenActions) => {
    switch (action.type) {
        case IdTokenActionsEnum.setIdToken:
            return {
                ...state,
                idToken: action.payload,
            };
        case IdTokenActionsEnum.setIsAuth:
            return {
                ...state,
                isAuth: action.payload,
            };
        case IdTokenActionsEnum.setIdTokenIsExpired:
            return {
                ...state,
                isTokenExpired: action.payload,
            };
        default:
            return state;
    }
};
