import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { StyledSnackbarStack } from './snackbars-stack.styles';
import { getSnackbars } from '../../../redux/snackbar/selectors';
import { Snackbar } from '../snackbar/snackbar';

export const SnackbarsStack: FC = () => {
    const allSnackbars = useSelector(getSnackbars);

    return (
        <StyledSnackbarStack>
            {allSnackbars.map(({ content, id, action, actionText, duration, type }) => (
                <Snackbar key={id} id={id} action={action} actionText={actionText} duration={duration} type={type}>
                    {content}
                </Snackbar>
            ))}
        </StyledSnackbarStack>
    );
};
