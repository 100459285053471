import { rem } from '../helpers/rem-converter';

// Названия и значения брать только с confluence. Если надо добавить новый, то просить дизайнера добавить новый брейк поинт с описанием
// https://confluence.mts.ru/pages/viewpage.action?pageId=696224635
export const screenResolutions = {
    desktop: {
        min: 960,
        max: undefined,
        description: 'фиксированная ширина, дальше едут только уши, ширина контейнера не изменяется.',
    },
    desktopL: {
        min: 1280,
        max: undefined,
        description: 'фиксированная ширина, дальше едут только уши, ширина контейнера не изменяется.',
    },
    desktopS: {
        min: 1024,
        max: 1279,
        description: 'xs, s и l можно схлопнуть в единый desktop, если контент тянется и не противоречит макетам',
    },
    desktopXS: {
        min: 960,
        max: 1023,
        description: 'xs, s и l можно схлопнуть в единый desktop, если контент тянется и не противоречит макетам',
    },
    tablet: { min: 768, max: 959 },
    mobile: { min: null, max: 767 },
} as const;

namespace Variables {
    /**
     * @deprecated
     */
    export const colors = {
        mainRed: '#FF0032',
        grayRed: '#EE0730',
        errorRed: '#F95721',

        white: '#FFFFFF',
        blackColor: '#000000',
        whiteSmokeColor: '#D8D8D8',
        linkWaterColor: '#E2E5EB',

        greyHeatherColor: '#BBC1C7',
        greyHeaterAccent: '#BCC3D0',
        greyChateauColor: '#9198A0',
        greyScrollColor: '#AEB5BD',
        greyChateauAccent: '#969FA8',
        greyRavenColor: '#6E7782',
        greyNevadaColor: '#626C77',
        athensGray: '#f2f3f7',
        seaShell: '#EFF0F3',
        shark: '#1D2023',
        lightStroke: 'rgba(188, 195, 208, 0.5)',

        lightBlackberryColor: '#6384E0',
        midnightBlueColor: '#202D3D',
        deepBlueColor: '#001424',
        primaryLinkInverted: '#45B6FC',
        normalBlueberryColor: '#0097FD',
        darkBlueberryColor: '#0077DB',
        darkestBlueberryColor: '#0048AA',
        normalBlackberryColor: '#014FCE',
        darkBlackberryColor: '#333795',
        dodgerBlue: '#007CFF',
        pickledBlueWood: '#354558',

        normalPlumColor: '#883888',
        darkMintColor: '#03A17B',
        darkestYellow: '#f37f19',

        paleGrayBlueColor: '#F4F5FB',
        snowColor: '#F9F9F9',
    };

    export const fonts = {
        fontRegular: 'MTSCompact-Regular, sans-serif',
        fontBold: 'MTSCompact-Bold, sans-serif',
        fontMedium: 'MTSCompact-Medium, sans-serif',
        fontWideMedium: 'MTSWide-Medium, sans-serif',
        fontTextMedium: 'MTSText-Medium, sans-serif',
        fontTextRegular: 'MTSText-Regular, sans-serif',
    };

    /**
     * @deprecated
     */
    export const border = {
        standard: '1px solid rgba(188, 195, 208, 0.5);',
        boldStandard: '2px solid rgba(188, 195, 208, 0.5);',
    };

    /**
     * @deprecated
     */
    export const background = {
        geolocation: 'rgba(188, 195, 208, 0.25)',
        phone: 'rgba(29, 32, 35, .4)',
    };

    /**
     * @deprecated
     */
    export const fontsize = {
        newStandard: `${rem(17)};`,
    };

    /**
     * @deprecated
     */
    export const filter = {
        balloon: `drop-shadow(0px 4px ${rem(24)} rgba(0, 0, 0, 0.12))
            drop-shadow(0px ${rem(12)} ${rem(20)} rgba(0, 0, 0, 0.14))`,
    };

    /**
     * @deprecated
     */
    export const borderRadiusSm = `${rem(4)};`;
    /**
     * @deprecated
     */
    export const borderRadiusMd = `${rem(8)};`;
    /**
     * @deprecated
     */
    export const borderRadiusLg = `${rem(16)}`;
    /**
     * @deprecated
     */
    export const borderRadiusXl = `${rem(100)}`;
    /**
     * @deprecated
     */
    export const borderBalloonTailRadius = `0 ${rem(5)}`;
    /**
     * @deprecated
     */
    export const borderRadiusRound = '50%';

    /**
     * @deprecated
     */
    export const shadows = {
        hover: `0 ${rem(4)} ${rem(24)} rgba(0, 0, 0, 0.12), 0 ${rem(8)} ${rem(16)} rgba(0, 0, 0, 0.08)`,
        header: `0 0 ${rem(6)} rgba(110, 119, 130, 0.14);`,
        geolocation: `0px ${rem(4)} ${rem(24)} rgba(0, 0, 0, 0.12), 0px ${rem(12)} ${rem(20)} rgba(0, 0, 0, 0.14)`,
        geolocationLine: `0px 0px ${rem(16)} rgba(0, 0, 0, 0.08), 0px ${rem(4)} ${rem(16)} rgba(0, 0, 0, 0.08)`,
        block: `0px ${rem(4)} ${rem(24)} rgba(0, 0, 0, 0.12), 0 ${rem(8)} ${rem(16)} rgba(0, 0, 0, 0.08)`,
    };

    /**
     * @deprecated
     */
    export const sidebarContainerHide = {
        standard: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.7) 44.27%, ${Variables.colors.white} 100%)`,
        dark: 'linear-gradient(180deg, rgba(29, 32, 35, 0) 0%, rgba(29, 32, 35, 0.7) 44.27%, #1D2023 100%)',
    };

    /**
     * @deprecated
     */
    export const zIndexList = {
        top: 99999,
        level5: 5,
        level4: 4,
        level3: 3,
        level2: 2,
        level1: 1,
        base: 0,
        bottom: -1,
        auto: 'auto',
    };

    export const nbWhiteSpace = '\xa0';
}

export default Variables;
