/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-env node */
import { getBrowserEnvs } from './env.helper';

const merge = require('deepmerge');
const { isServer } = require('./isServer');
const packageJson = require('../../package.json');

const APP_VERSION = packageJson.version;

let envs: unknown = null;

function updEnv(browserEnvs = {}) {
    if (isServer) {
        const stand = process.env.NEXT_PUBLIC_STAND;
        if (!envs && stand) {
            const envFromFile = require(`../../environments/env.${stand}`);
            let envBase = {};
            let envFromFileTemp = {};

            try {
                envBase = require('../../environments/env.base') || {};
                // eslint-disable-next-line no-empty
            } catch (e) {}

            try {
                envFromFileTemp = require(`../../environments/env.${stand}.temp`) || {};
                // eslint-disable-next-line no-empty
            } catch (e) {}

            const envVars = merge.all([envBase, envFromFile, envFromFileTemp]);
            envs = { APP_VERSION, ...envVars };
            process.env = merge(envs, process.env);
            return envs;
        }
    } else {
        window.supportEnv = getBrowserEnvs(browserEnvs);
    }
}

export { updEnv };
