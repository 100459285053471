import styled, { css, FlattenInterpolation, ThemedStyledProps } from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { Themeinterface } from '../../../styles/themes/theme.type';
import { borderRadius } from '../../../styles/tokens/border';
import { color } from '../../../styles/tokens/color';
import { fontSize } from '../../../styles/tokens/font-size';

export const Textarea = styled.textarea<{ inputCss?: FlattenInterpolation<ThemedStyledProps<{ theme: Themeinterface }, unknown>> }>`
    width: 100%;
    box-sizing: border-box;
    padding: 0 ${rem(10)};
    padding-top: ${rem(11)};
    padding-bottom: ${rem(9)};
    border: ${rem(2)} solid ${({ theme }) => theme.backgroundStroke};
    border-radius: ${borderRadius.radius12};
    font-size: ${rem(17)};
    line-height: ${rem(24)};
    outline: none;
    background-color: ${({ theme }) => theme.backgroundSecondary};
    color: ${({ theme }) => theme.textPrimary};

    &:focus {
        border: 2px solid ${({ theme }) => theme.accentActive};
    }

    ${({ inputCss }) => inputCss};
`;

export const Textbox = styled(Textarea)`
    background-color: ${({ theme }) => theme.backgroundSecondary};
    && {
        max-height: ${rem(44)};
        padding-left: ${rem(40)};
        ${(p) =>
        p.value &&
            css`
                padding-right: ${rem(45)};
            `};
        font-size: ${fontSize.standard};
        line-height: ${rem(24)};
    }

    &::placeholder {
        color: ${color.textLightSecondary};
    }

    ${({ inputCss }) => inputCss};
`;

export const BeforeIcon = styled.div`
    left: ${rem(15)};
    position: absolute;
    display: flex;
    align-items: center;
`;

export const TextboxContainer = styled.label`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
`;
export const CrossWrapper = styled.div`
    display: flex;
    position: absolute;
    right: ${rem(18)};
    cursor: pointer;
`;
