import { NextRouter } from 'next/router';
import { useAppRouter } from '../custom-hooks/use-app-router';
import { Product } from '../models/product';
import { Section } from '../models/section';

export namespace RouterHelper {
    export const getProductSEFUrl = (): string => {
        const router = useAppRouter();

        return (router.query.product as string) || '';
    };

    export const getChildSegmentCode = (): string => {
        const router = useAppRouter();

        return (router.query.child_segment as string) || '';
    };

    export const getSectionSEFUrl = (): string => {
        const router = useAppRouter();

        return (router.query.section as string) || '';
    };

    export const getArticleSEFUrl = (): string => {
        const router = useAppRouter();

        return (router.query.article as string) || '';
    };

    export const checkOnSection = (): boolean => {
        const router = useAppRouter();

        return /\[section]$/.test(router.route);
    };

    export const checkOnProduct = (): boolean => {
        const router = useAppRouter();

        return /\[product]$/.test(router.route);
    };

    export const checkOnChildSegment = (): boolean => {
        const router = useAppRouter();

        return router.route.includes('/child_segment/[child_segment]');
    };

    export const checkOnArticle = (): boolean => {
        const router = useAppRouter();

        return /\[article]$/.test(router.route);
    };

    export const checkOnlyOneSection = (product: Product): boolean => {
        return product.totalSections === 1;
    };

    export const checkOnlyOneArticle = (section: Section): boolean => {
        return section?.totalArticles === 1;
    };

    export const getAnchorFromUrl = (path: string): string => {
        const [, anchor] = path.split('#');
        return anchor;
    }
}

export namespace locationPathNavigator {
    export const goBackPath = (router: NextRouter, backDeep = 1): string => {
        const currentPath = router.asPath.split('?')[0];
        return currentPath.replace(new RegExp(`(\/[a-zA-Z-_0-9]+){${backDeep}}$`, 'g'), '');
    };
}
