import { isServer } from './isServer';

// хак, который отменяет автоскролл к последней позиции скролла браузером при перезагрузке, если есть якорь
export const fixScrollIntoAnchor = () => {
    if (!isServer) {
        const anchor = document.location.hash.slice(1);
        if (anchor) {
            history.scrollRestoration = 'manual';
            window.addEventListener(
                'load',
                () => {
                    setTimeout(() => (history.scrollRestoration = 'auto'));
                },
                { once: true },
            );
        }
    }
};
