import { FC } from 'react';
import * as Styled from './loader-circle.styled';
import { color } from '../../../styles/tokens/color';

export enum loaderTypes {
    primary,
    dark,
    light,
    error,
}

export enum loaderSizes {
    small = 0.5,
    medium = 1,
    large = 2,
}

const loaderColors = {
    [loaderTypes.primary]: color.loaderPrimary,
    [loaderTypes.dark]: color.loaderDark,
    [loaderTypes.light]: color.loaderLight,
    [loaderTypes.error]: color.loaderError,
};

interface LoaderCircleProps {
    type?: loaderTypes;
    size?: loaderSizes;
    className?: string;
    centered?: boolean;
}
export const LoaderCircle: FC<LoaderCircleProps> = ({
    type = loaderTypes.dark,
    size = loaderSizes.medium,
    centered,
    className,
}) => (
    <Styled.Wrapper centered={centered}>
        <Styled.Loader className={className} color={loaderColors[type]} scale={size} />
    </Styled.Wrapper>
);
