import { selectFeedbackStep } from './ui-selectors';
import { feedbackStepEnum, UIActionsEnum } from './ui-types';
import { selectRatingReasons, selectArticleRating } from '../article/article-selectors';
import store from '../store';

export const nextFeedbackStep = () => {
    const state = store.getState();
    const rating = selectArticleRating(state);
    const currentStep = selectFeedbackStep(state);
    const currentReason = selectRatingReasons(state).find((reason) => reason.id === rating.articleUselessnessReasonId);

    let nextStep = feedbackStepEnum.firstQuestion;

    if (currentStep === feedbackStepEnum.firstQuestion) {
        nextStep = rating.usefulness ? feedbackStepEnum.success : feedbackStepEnum.reasons;
    } else if (currentStep === feedbackStepEnum.reasons) {
        nextStep = currentReason?.allowCustomComment ? feedbackStepEnum.comment : feedbackStepEnum.success;
    } else if (currentStep === feedbackStepEnum.comment) {
        nextStep = feedbackStepEnum.success;
    }

    return {
        type: UIActionsEnum.setFeedbackStep,
        payload: nextStep,
    };
};

export const prevFeedbackStep = () => {
    const state = store.getState();
    const currentStep = selectFeedbackStep(state);

    let nextStep = feedbackStepEnum.firstQuestion;

    if (currentStep === feedbackStepEnum.reasons) {
        nextStep = feedbackStepEnum.firstQuestion;
    } else if (currentStep === feedbackStepEnum.comment) {
        nextStep = feedbackStepEnum.reasons;
    }

    return {
        type: UIActionsEnum.setFeedbackStep,
        payload: nextStep,
    };
};

export const resetFeedbackStep = {
    type: UIActionsEnum.setFeedbackStep,
    payload: feedbackStepEnum.firstQuestion,
};

export const setGeoModalOpened = (newOpenedState: boolean) => ({
    type: UIActionsEnum.geoModalOpened,
    payload: newOpenedState,
});

export const setIsGlobalLoading = (newLoadingState: boolean) => ({
    type: UIActionsEnum.setIsGlobalLoading,
    payload: newLoadingState,
});

export const setMainPageGroupLoading = (loadingProductId: number) => ({
    type: UIActionsEnum.setMainPageGroupLoading,
    payload: loadingProductId,
});

export const stopMainPageGroupLoading = () => ({
    type: UIActionsEnum.setMainPageGroupLoading,
    payload: null,
});

export const setSearchResultCardLoading = (loadingId: number) => ({
    type: UIActionsEnum.setSearchResultCardLoading,
    payload: loadingId,
});
export const stopSearchResultCardLoading = () => ({
    type: UIActionsEnum.setSearchResultCardLoading,
    payload: null,
});
