import React, {FC, SVGProps} from 'react';

export const Whatsapp: FC = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg
        width={44}
        height={44}
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m0 44 3.784-11.351A21.507 21.507 0 0 1 .759 21.62C.759 9.68 10.439 0 22.379 0 34.32 0 44 9.68 44 21.62c0 11.941-9.68 21.621-21.62 21.621-3.634 0-7.055-.9-10.06-2.483L0 44Z"
            fill="#2CB742"
        />
        <path
            d="M36.173 28.816c-.998-1.886-4.68-4.044-4.68-4.044-.833-.475-1.838-.528-2.313.319 0 0-1.196 1.434-1.5 1.64-1.39.942-2.677.906-3.977-.394l-3.02-3.02-3.02-3.02c-1.3-1.3-1.336-2.587-.395-3.977.207-.304 1.641-1.5 1.641-1.5.847-.476.794-1.48.319-2.313 0 0-2.158-3.682-4.044-4.68a2.068 2.068 0 0 0-2.43.365L11.42 9.526c-4.23 4.23-2.147 9.007 2.083 13.238l3.867 3.867 3.867 3.866c4.23 4.231 9.007 6.314 13.238 2.084l1.333-1.334a2.07 2.07 0 0 0 .365-2.43Z"
            fill="#fff"
        />
    </svg>
);