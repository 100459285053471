import React, { useEffect } from 'react';
import App, { AppContext, AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { CookiesAccept } from '../components/containers/cookies-accept/cookies-accept';
import { GlobalLoader } from '../components/containers/global-loader/global-loader';
import { SearchGeolocationModal } from '../components/containers/search-geolocation-modal/search-geolocation-modal';
import { SeoForHead } from '../components/containers/seo-for-head/seo-for-head';
import { SnackbarsStack } from '../components/containers/snackbars-stack/snackbars-stack';
import RootLayout from '../components/layout/root-layout';
import Footer from '../components/sections/footer/footer';
import Header from '../components/sections/header/header';
import Shell from '../components/shell.component';
import '@support-editor/mts-editor-dist/dist/style.css';
import { abTestCookie } from '../constants/cookies';
import { ABTestContext } from '../context/ab-test-context';
import { FirstVisitContext } from '../context/first-visit-context';
import { UserAgentContext } from '../context/user-agent-context';
import { abTestHook } from '../custom-hooks/ab-test.hook';
import { useClearSiteData } from '../custom-hooks/use-clear-site-data';
import useFirstVisit from '../custom-hooks/use-first-visit.hook';
import { useInitialProps } from '../custom-hooks/use-initial-props.hook';
import { getCookie, parseCookie, setCookie } from '../helpers/cookies';
import { fixScrollIntoAnchor } from '../helpers/scroll-into-anchor';
import { userAgent } from '../helpers/user-agent';
import { ABTestAnalytics } from '../helpers/web-analytics/ab-test-analytics';
import { contentSupport, title } from '../meta-tags';
import { ColorThemeEnum } from '../models/enums/color-theme.enum';
import { PagePropsModel } from '../models/page-props.model';
import { nextWrapper } from '../redux/store';
import { GlobalStyle } from '../styles/global/styled-global';
import { darkTheme } from '../styles/themes/dark';
import { lightTheme } from '../styles/themes/light';
import { ChatMessenger } from '../components/sections/chat-messenger/chat-messenger';
import { useIsApp } from '../custom-hooks/use-is-app';

fixScrollIntoAnchor();

const Application = ({ Component, ...rest }: AppProps) => {
    useInitialProps((rest?.pageProps as PagePropsModel)?.env);
    const { store, props } = nextWrapper.useWrappedStore(rest);
    const isFirstVisit = useFirstVisit();

    const isDark = props.theme === ColorThemeEnum.dark;
    const colorTheme = isDark ? darkTheme : lightTheme;
    const themeName = isDark ? ColorThemeEnum.dark : ColorThemeEnum.light;

    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log(`support app version: %c${process.env.APP_VERSION}`, 'color: #263238; font-weight: bold;');
        const abTest = getCookie(abTestCookie);
        ABTestAnalytics.userGroup(process.env.APP_VERSION, props.abTest.mts_bank_group ? 1 : 0);
        if (!abTest) {
            setCookie(abTestCookie, JSON.stringify(props.abTest), 30);
        }
        setCookie('SUPPORT_MTS_THEME', themeName);
    }, []);

    useClearSiteData();
    const { isApp } = useIsApp();

    return (
        <Provider store={store}>
            <UserAgentContext.Provider value={userAgent}>
                <ThemeProvider theme={colorTheme}>
                    <SnackbarsStack/>
                    <CookiesAccept/>
                    <SeoForHead title={title} descriptionContent={contentSupport}/>
                    <GlobalStyle
                        theme={colorTheme}
                        isApp={isApp}
                    />
                    <Shell>
                        <RootLayout>
                            <GlobalLoader />
                            <ABTestContext.Provider value={props.abTest}>
                                <FirstVisitContext.Provider value={isFirstVisit}>
                                    <SearchGeolocationModal />
                                    <Header theme={themeName} />
                                    <Component {...props.pageProps} />
                                    <ChatMessenger isDark={isDark} />
                                </FirstVisitContext.Provider>
                            </ABTestContext.Provider>
                            <Footer />
                        </RootLayout>
                    </Shell>
                </ThemeProvider>
            </UserAgentContext.Provider>
        </Provider>
    );
};

Application.getInitialProps = async (context: AppContext) => {
    const appProps = await App.getInitialProps(context);
    const { query } = context.router;
    const cookies = parseCookie(context.ctx.req?.headers.cookie);
    // todo: уточнить какой будет точный query и какой ключ будет у куки, и что приоритетнее
    const theme = query.theme || cookies.SUPPORT_MTS_THEME;
    const abTest = cookies[abTestCookie] ? JSON.parse(cookies[abTestCookie]) : abTestHook();

    return { ...appProps, theme, abTest };
};

export default Application;
