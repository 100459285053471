export enum ChannelTypeEnum {
    phone = 'PHONE',
    telegram = 'TELEGRAM',
    whatsapp = 'WHATSAPP',
}

export enum WidgetTypeEnum {
    supportChat = 'SUPPORT_CHAT',
    messengerChat = 'MESSENGER_CHAT',
}
