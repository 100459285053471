import React, {SVGProps} from 'react';

export const ArrowDownIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg
        width={12}
        height={7}
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M1.707.293A1 1 0 0 0 .293 1.707l5 5a1 1 0 0 0 1.414 0l5-5A1 1 0 0 0 10.293.293L6 4.586 1.707.293Z"
            fill="#000"
        />
    </svg>
);