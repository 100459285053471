export const telephoneNumberFormat = (number: string) => {
    return (
        number.substring(0, 3).concat(' ') +
        number.substring(3, 6).concat('-') +
        number.substring(6, 8).concat('-') +
        number.substring(8, 10)
    );
};

// 88000000000 -> 8 800 000-00-00
export const formatPhoneNumber = (number: string) => {
    return number.length === 11 ?
        (number.substring(0, 1).concat(' ') +
          number.substring(1, 4).concat(' ') +
          number.substring(4, 7).concat('-') +
          number.substring(7, 9).concat('-') +
          number.substring(9, 11)) :
        number;
};

// +78000000000 -> +7 800 000-00-00
export const formatRoamingPhoneNumber = (number: string) => {
  return number.length === 12 ? (
    number.substring(0, 2).concat(' ') +
    number.substring(2, 5).concat(' ') +
    number.substring(5, 8).concat('-') +
    number.substring(8, 10).concat('-') +
    number.substring(10, 12)) :
    number;
}
