export enum logLevels {
    info = 'info',
    debug = 'debug',
    error = 'error',
    warn = 'warn',
}

export type LogType = {
    level: logLevels;
    label?: string;
    data: string | unknown;
};
