/* eslint-env node */
module.exports = {
    NEXT_PUBLIC_STAND: 'dev',
    NODE_TLS_REJECT_UNAUTHORIZED: '0',
    BASE_URL: 'https://dev.support.mts.ru',
    HEADER_ENV: 'stage',
    CHAT_ENV: 'dev',
    API_URLS: {
        NODE_SUPPORT: 'http://support-backend:8089/api/v1',
        SUPPORT: 'https://dev.support.mts.ru/api/v1',
    },
    WEBSOCKETS: {
        CHAT: 'wss://dev.online-chat.mts.ru/ws/chat',
    },
};
