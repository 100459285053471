import styled from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { media, mobile, tablet } from '../../../styles/themes/media';
import { color } from '../../../styles/tokens/color';
import Variables from '../../../styles/variables';
import { Menu } from '../segments-menu/segments-menu.styles';

export const HeaderTopControls = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: ${Variables.fonts.fontMedium};
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    padding: ${rem(6)} 0;
    color: ${color.textLightSecondary};

    ${media(tablet, mobile)`
        border-bottom: none;

        ${Menu} {
            display: none;
        }
    `};
`;
