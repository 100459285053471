import styled, { css } from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { Themeinterface } from '../../../styles/themes/theme.type';
import { zIndex } from '../../../styles/tokens/zIndex';

export const SearchContainer = styled.div<{ isLoading?: boolean }>`
    position: relative;
    display: flex;

    &:after {
        content: '';
        background-color: transparent;
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: all 0.2s linear;
        ${({ isLoading }) =>
        isLoading &&
            css`
                background-color: ${({ theme }) => theme.loaderBackground};
            `};
        z-index: ${zIndex.level2};
    }
`;

export const CustomInputStyles = css<{ theme: Themeinterface }>`
    background-color: ${({ theme }) => theme.backgroundSecondary};
    border-color: transparent;
    caret-color: ${({ theme }) => theme.textPrimary};

    &:-webkit-autofill {
        border: ${rem(2)} solid ${({ theme }) => theme.accentActive};
        transition: all 5000s ease-in-out 0s;
    }
`;
