import { Article } from '../../models/article';
import { Product } from '../../models/product';
import { Rating } from '../../models/rating';
import { RatingReason } from '../../models/rating-reason';
import { Section } from '../../models/section';

export class ArticleState {
    currentArticle: Article = new Article();
    currentProduct: Product = new Product();
    currentSection: Section = new Section();
    articleRating: Rating = new Rating();
    ratingReasons: RatingReason[] = [];
    cache = {
        articles: {} as Record<string, Article[]> | undefined,
    };
    isProductHidden: boolean = false;
}

export enum ArticleActionsEnum {
    setCurrentArticle = 'SET_CURRENT_ARTICLE',
    updateCacheArticles = 'UPDATE_CACHE_ARTICLES',
    setCurrentProduct = 'SET_CURRENT_PRODUCT',
    setCurrentSection = 'SET_CURRENT_SECTION',
    setArticleRating = 'SET_ARTICLE_RATING',
    setRatingReasons = 'SET_RATING_REASONS',
    setIsProductHidden = 'SET_IS_PRODUCT_HIDDEN'
}

interface SetCurrentArticleAction {
    type: typeof ArticleActionsEnum.setCurrentArticle;
    payload: Article;
}

interface updateCacheArticlesAction {
    type: typeof ArticleActionsEnum.updateCacheArticles;
    payload: Record<string, Article[]>;
}

interface SetCurrentProductAction {
    type: typeof ArticleActionsEnum.setCurrentProduct;
    payload: Product;
}

interface SetCurrentSectionAction {
    type: typeof ArticleActionsEnum.setCurrentSection;
    payload: Section;
}

interface SetArticleRatingAction {
    type: typeof ArticleActionsEnum.setArticleRating;
    payload: Rating;
}

export interface SetRatingReasonsAction {
    type: ArticleActionsEnum.setRatingReasons;
    payload: RatingReason[];
}

export interface SetIsProductHiddenAction {
    type: ArticleActionsEnum.setIsProductHidden;
    payload: boolean;
}

export type ArticleActions =
    | SetCurrentArticleAction
    | updateCacheArticlesAction
    | SetCurrentProductAction
    | SetCurrentSectionAction
    | SetArticleRatingAction
    | SetRatingReasonsAction
    | SetIsProductHiddenAction;
