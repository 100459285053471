import { AxiosResponse } from 'axios';
import { AfterMiddlewareType } from '../types';

export const catchEmptyData: AfterMiddlewareType = (response) => {
    if (response.status !== 200) {
        return {
            ...response,
            data: null,
        } as AxiosResponse;
    }
    return response;
};
