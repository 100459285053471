import { SnackbarActions, SnackbarActionsEnum, Snackbars } from './types';

const initialState = new Snackbars();

const snackbarReducer = (state = initialState, action: SnackbarActions) => {
    switch (action.type) {
        case SnackbarActionsEnum.openSnackbar:
            return {
                ...state,
                list: [...state.list, action.payload],
            };

        case SnackbarActionsEnum.closeSnackbar:
            if (action.payload.id === undefined) {
                return state;
            }

            return {
                ...state,
                list: state.list.filter((snackbar) => snackbar.id !== action.payload.id),
            };
        default:
            return state;
    }
};

export default snackbarReducer;
