export class CurrentUserModel {
    public id: string | null = null;
    public name: string = '';
    public abonent: number | null = null;
    public numberType: string | null = null;
    public accountType: string | null = null;
    public currentTariff: string | null = null;
    public phone: number | string | null = null;
    public isAuth: 1 | 0 = 0;
}
