import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppRouter } from './use-app-router';
import { isDateExpired } from '../helpers/is-date-expired.helper';
import { getItemFromLocalStorage, removeItemFromLocalStorage, setItemToLocalStorage } from '../helpers/local-storage.helper';
import { Product } from '../models/product';
import { StateModel } from '../models/state.model';
import { selectCurrentProduct, selectWidgetChat } from '../redux/article/article-selectors';

export interface PartialProduct {
    code: string | null;
    name: string | null;
}

export type useProductForChatHookType = () => {
    productForChat: PartialProduct | null;
    setProductForChat: () => void;
    clearProductForChat: () => void;
};

export const productForChatFields = {
    date: 'support_product_for_chat_date',
    product: 'support_product_for_chat',
};

export const useProductForChatHook: useProductForChatHookType = () => {
    const router = useAppRouter();

    const [ product, setProduct ] = useState<PartialProduct | null>(null);
    const storeWidgetChat = useSelector(selectWidgetChat);
    const storeProduct = useSelector<StateModel, Product>(selectCurrentProduct);

    const liveDurationMin = 15;

    /*
    при посещении страниц
    - если дата истекла: очищаем LocalStorage продукта
    - если дата не истекла: устанавливаем состояние продукта для поддержания чат сессии
    */
    useEffect(() => {
        const expirationDate = getItemFromLocalStorage(productForChatFields.date);

        if (expirationDate && !isDateExpired(expirationDate, liveDurationMin)) {
            const productString = getItemFromLocalStorage(productForChatFields.product);
            const productObj = productString ? JSON.parse(productString) : null;

            setProduct(productObj);
        }

        if (expirationDate && isDateExpired(expirationDate, liveDurationMin)) {
            clearProductForChat();
        }

    }, [router.asPath]);

    const setProductForChat = () => {
        if (product?.code && product?.name) {
            // если чат повторно вызывает поддержание чат сессии - продукт уже лежит в
            // стейте - просто обновляем дату
            setItemToLocalStorage(productForChatFields.date, new Date().toString());
        } else if (storeProduct?.code && storeProduct?.name) {
            // если продукта в стейте нет - это первый вызов чата поддержания сессии - устанавливаем
            // в стейт текущий продукт на котором находится пользователь
            const productForStorage = {
                name: storeProduct.name,
                code: storeProduct.code,
                widget: storeWidgetChat,
            };

            setItemToLocalStorage(productForChatFields.date, new Date().toString());
            setItemToLocalStorage(productForChatFields.product, JSON.stringify(productForStorage));
            setProduct(productForStorage);
        }
    };

    const clearProductForChat = () => {
        removeItemFromLocalStorage(productForChatFields.date);
        removeItemFromLocalStorage(productForChatFields.product);
        setProduct(null);
    };

    return {
        productForChat: product,
        setProductForChat,
        clearProductForChat,
    };
};
