import React, {SVGProps} from 'react';

export const B2cChatIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg
        width="22"
        height="19"
        viewBox="0 0 22 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0.600098 4.3999V12.9839C0.600098 13.418 1.11525 13.6459 1.43643 13.3539L2.47298 12.4116C3.20926 11.7422 4.1686 11.3713 5.16367 11.3713H10.863C13.0721 11.3713 14.863 9.58047 14.863 7.37133V4.3999C14.863 2.19076 13.0721 0.399902 10.863 0.399902H4.6001C2.39096 0.399902 0.600098 2.19076 0.600098 4.3999Z"
            fill="white"/>
        <path
            d="M18.1715 5.61133H16.783V9.56561C16.783 11.7748 14.9921 13.5656 12.783 13.5656H6.90867C6.90867 15.2225 8.25181 16.5828 9.90867 16.5828H16.3242C17.3249 16.5828 18.2893 16.9579 19.0271 17.6341L20.3337 18.8319C20.6544 19.1258 21.1715 18.8983 21.1715 18.4633V8.61133C21.1715 6.95448 19.8284 5.61133 18.1715 5.61133Z"
            fill="white"/>
    </svg>
);