import React from 'react';
import { StyledGeolocationFoundList } from './geolocation-found-list.styles';
import { GeolocationData } from '../../../models/geolocation';

interface GeolocationFoundListProps {
    listSubject: Array<GeolocationData>;
    onClick: (params: GeolocationData) => void;
}

const GeolocationFoundList: React.FC<GeolocationFoundListProps> = ({ listSubject, onClick }) => {
    return (
        <StyledGeolocationFoundList>
            {listSubject.length > 0 &&
                listSubject.map((item, key) => {
                    return (
                        <div className="item" key={key} onClick={() => onClick(item)}>
                            {item.name}
                            <span>{item.parent?.name}</span>
                        </div>
                    );
                })}
        </StyledGeolocationFoundList>
    );
};

export default GeolocationFoundList;
