import React, {FC, SVGProps} from 'react';

export const TelephoneIcon: FC = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg
        width={44}
        height={44}
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx={22} cy={22} r={22} fill="#E30611" />
        <path
            d="M35 22h-.006c0-7.18-5.82-13-12.997-13C14.819 9 9 14.82 9 22v3.249c0 .242.006.483.017.721.07 1.484.104 2.226.914 3.01.809.786 1.478.796 2.817.818h.306c1.338-.022 2.006-.033 2.814-.817.808-.784.843-1.525.912-3.007a15.554 15.554 0 0 0 0-1.45c-.07-1.483-.104-2.224-.912-3.007-.808-.784-1.476-.795-2.814-.817a9.99 9.99 0 0 0-.155-.001H11.68c.64-5.13 5.015-9.1 10.317-9.1 5.302 0 9.677 3.97 10.317 9.1-.458 0-.916-.006-1.374.001-1.338.022-2.007.033-2.815.817-.808.783-.842 1.524-.912 3.007a15.421 15.421 0 0 0 0 1.45c.07 1.482.104 2.224.912 3.007.808.784 1.477.795 2.815.816.311.006.624-.002.936-.013-.128.312-.347.655-.827 1.138-.582.584-.972.783-1.378.908-.547.169-1.227.24-2.63.362-.744.065-1.56.118-2.445.155A2.594 2.594 0 0 0 22 29.803 2.598 2.598 0 1 0 21.997 35c2.014 0 3.771-.088 5.27-.218 2.631-.228 3.947-.343 5.623-2.025 1.674-1.68 1.78-2.941 1.99-5.459L35 22Z"
            fill="#fff"
        />
    </svg>
);
