
import { FC, useEffect, useState } from 'react';
import * as Styled from './header-top-controls-spacer.styles';
import { useAppRouter } from '../../../custom-hooks/use-app-router';
import { useIsApp } from '../../../custom-hooks/use-is-app';
import { menuItems } from '../segments-menu/segments-menu.constants';

export const HeaderTopControlsSpacer: FC<{ isModalConfirmGeoOpened: boolean }> = ({ isModalConfirmGeoOpened }) => {
    const { isApp } = useIsApp();
    const [withMenuOnMobile, setWithMenuOnMobile] = useState(true);
    const router = useAppRouter();
    const routerPathName = router.pathname;
    const segmentsHref = menuItems.map(i => i.href);

    useEffect(() => {
        setWithMenuOnMobile(segmentsHref.includes(routerPathName));
    }, [routerPathName]);

    return <Styled.Wrapper
        withMenuOnMobile={withMenuOnMobile}
        isModalConfirmGeoOpened={isModalConfirmGeoOpened}
        isApp={isApp}
    />;
};
