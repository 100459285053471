import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

const utmAppKeys = {
    utm_source: 'inhouse',
    utm_medium: 'mymts',
    utm_webview: 'app',
};

const checkUtm = (query: string = '') => {
    const source = `utm_source=${utmAppKeys.utm_source}`;
    const medium = `utm_medium=${utmAppKeys.utm_medium}`;
    const webview = `utm_medium=${utmAppKeys.utm_webview}`;

    return query.includes(medium) && query.includes(source) || query.includes(webview);
};

export const useIsApp = () => {
    const router = useRouter();
    const query = router.asPath.split('?')[1];
    const [isApp, setIsApp] = useState(false);
    const [isMyMtsApp, setIsMyMtsApp] = useState(false);
    const [isWebviewApp, setIsWebviewApp] = useState(false);

    useEffect(() => {
        const isUtm = checkUtm(query);
        setIsApp(isUtm);

        if (isUtm) {
            if (query.includes(utmAppKeys.utm_source) && query.includes(utmAppKeys.utm_medium)) {
                setIsMyMtsApp(true);
            } else if (query.includes(utmAppKeys.utm_webview)) {
                setIsWebviewApp(true);
            }
        }
    }, [query]);

    return { isApp, isMyMtsApp, isWebviewApp };
};
