export class ChatState {
    isVisibleModalTelephone: boolean = false;
}

export enum ChatActionsEnum {
    setModalTelephoneVisible = 'SET_MODAL_TELEPHONE_VISIBLE',
}

interface SetModalVisibleAction {
    type: typeof ChatActionsEnum.setModalTelephoneVisible;
    payload: boolean;
}

export type ChatActions = SetModalVisibleAction;
