import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

const useFirstVisit = () => {
    const router = useRouter();
    const [isFirstVisit, setIsFirstVisit] = useState<boolean>(false);
    const lastPathnameRef = useRef<string>(router.pathname);
    const lastArticleRef = useRef<string | string[] | undefined>(router.query.article);

    useEffect(() => {
        const hasVisited = sessionStorage.getItem('hasVisited');

        if (!hasVisited) {
            setIsFirstVisit(true);
            sessionStorage.setItem('hasVisited', 'true');
        }

        const handleRouteChange = () => {
            const routerTest = lastPathnameRef.current === router.pathname;
            const articleTest = lastArticleRef.current === router.query.article;
            if (!routerTest || !articleTest) {
                setIsFirstVisit(false);
            }
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router]);

    return isFirstVisit;
};

export default useFirstVisit;
