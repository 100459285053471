import { isServer } from './isServer';
import { findByKeys } from './object.helper';

export interface ProcessEnv {
    [key: string]: string | number;
}

export const getBrowserEnvs = (processEnv: NodeJS.ProcessEnv | ProcessEnv) => {
    if (processEnv) {
        return {
            NEXT_PUBLIC_STAND: processEnv.NEXT_PUBLIC_STAND,
            HEADER_ENV: processEnv.HEADER_ENV,
            CHAT_ENV: processEnv.CHAT_ENV,
            BASE_URL: processEnv.BASE_URL,
            API_URLS: processEnv.API_URLS,
            WEBSOCKETS: processEnv.WEBSOCKETS,
        };
    }
};

export const getEnv = (arrayKeys: string[]) => {
    return findByKeys(isServer ? process.env : window.supportEnv, arrayKeys);
};

export const isProd = () => {
    return !['dev', 'local'].includes(<string>envService.getStand());
};

const getSupportApi = () => {
    if (isServer) {
        return findByKeys(process.env, ['API_URLS', 'NODE_SUPPORT']);
    } else {
        return findByKeys(window.supportEnv, ['API_URLS', 'SUPPORT']);
    }
};

export const envService = {
    getSupportApi: () => getSupportApi(),
    getBaseUrl: () => getEnv(['BASE_URL']),
    getStand: () => getEnv(['NEXT_PUBLIC_STAND']),
    getChatStand: () => getEnv(['CHAT_ENV']),
    getHeaderStand: () => getEnv(['HEADER_ENV']),
};
