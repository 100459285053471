import { isServer } from './isServer';

const generateExpires = (days?: number) => {
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        return '; expires=' + date.toUTCString();
    }
    return '';
};

export const setCookie = (name: string = '', value: string = '', days?: number, encode?: boolean) => {
    const expires = generateExpires(days);

    const encodedName = encode ? encodeURIComponent(name) : name;
    const encodedValue = encode ? value && encodeURIComponent(value) : value;

    const cookie = `${encodedName}=${encodedValue}${expires}; path=/`;

    if (!isServer && name) {
        document.cookie = cookie;
    }

    return cookie;
};

export const getCookie = (_name: string) => {
    if (isServer) {
        return;
    }
    const name = _name + '=';
    const splittedCookie = document.cookie.split(';');

    for (let i = 0; i < splittedCookie.length; i++) {
        let cookie = splittedCookie[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return undefined;
};

export const appendToCookieString = (cookieString: string = '', name: string, value: string, days?: number) => {
    const expires = generateExpires(days);
    const encodedName = encodeURIComponent(name);
    const encodedValue = encodeURIComponent(value);
    let prefix = '';

    if (cookieString) {
        prefix += '; ';
    }

    return cookieString + prefix + encodedName + '=' + encodedValue + expires;
};

export const deleteCookie = (name: string) => {
    if (!document) {
        return;
    }
    document.cookie = name + '=; Max-Age=0';
};

type ParsedCookieType = Record<string, string>;

export const parseCookie = (cookieString: string | undefined): ParsedCookieType => {
    const list = {};
    if (!cookieString) {
        return list;
    }

    cookieString.split(';').forEach(function (cookie) {
        const [name, ...rest] = cookie.split('=');
        const trimmedName = name?.trim();
        if (!trimmedName) {
            return;
        }
        const value = rest.join('=').trim();
        if (!value) {
            return;
        }
        // @ts-ignore
        list[trimmedName] = decodeURIComponent(value);
    });

    return list;
};
