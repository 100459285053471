import styled, { css } from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { scrollbar } from '../../../styles/shared-css/custom-scroll';
import {
    desktopHide,
    mediaHide,
    mobileHide,
    tabletHide,
} from '../../../styles/themes/media';
import Variables from '../../../styles/variables';

const styledFont = css`
    color: ${({ theme }) => theme.textPrimaryLink};
    font-size: ${rem(17)};
    line-height: ${rem(24)};
`;

const styledHover = css`
    color: ${({ theme }) => theme.textPrimaryLinkHover};
    cursor: pointer;
    text-decoration: underline;
`;

export const StyledGeolocation = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: ${Variables.zIndexList.top};

    .desktop-geolocation {
        ${mediaHide(mobileHide, tabletHide)};
    }

    .mobile-geolocation {
        ${desktopHide};
    }
`;

export const ModalWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: ${({ theme }) => theme.backgroundOverlayModal};
    z-index: ${Variables.zIndexList.top};
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper-content {
        position: relative;
        max-width: ${rem(800)};
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .close {
            margin-left: ${rem(16)};
            position: absolute;
            top: 1.5%;
            cursor: pointer;
            left: 91%;
        }
    }
`;

export const SearchGeolocationModalStyles = styled.div`
    max-width: ${rem(656)};
    width: 100%;
    padding: ${rem(24)} ${rem(24)} ${rem(20)};
    background: ${({ theme }) => theme.backgroundPrimary};
    box-sizing: border-box;
    box-shadow: ${Variables.shadows.geolocation};
    border-radius: ${Variables.borderRadiusLg};
    overflow-x: hidden;
    position: relative;
    top: -10%;

    .title {
        font-family: ${Variables.fonts.fontMedium};
        font-size: ${rem(24)};
        color: ${({ theme }) => theme.textPrimary};
        line-height: ${rem(28)};
        margin-bottom: ${rem(16)};
    }

    .search-input {
        margin-bottom: ${rem(20)};
    }

    .content {
        display: flex;
        justify-content: space-between;
        max-height: ${rem(306)};

        .regions {
            width: 100%;
            border-right: 1px solid ${({ theme }) => theme.backgroundStroke};
            overflow-y: auto;
            ${scrollbar};

            .block-region {
                margin-bottom: ${rem(12)};

                .letter {
                    ${styledFont};
                    font-family: ${Variables.fonts.fontMedium};
                    color: ${({ theme }) => theme.textPrimary};
                    margin-bottom: ${rem(8)};
                }

                li {
                    ${styledFont};

                    &:hover {
                        ${styledHover};
                    }
                }
            }
        }

        .cities {
            width: 100%;
            margin-left: ${rem(23)};
            overflow-y: auto;
            ${scrollbar};

            .active {
                color: ${({ theme }) => theme.textPrimaryLink};
            }

            li {
                ${styledFont};

                &:hover {
                    ${styledHover};
                }
            }
        }
    }
`;
