import api from './api/api';
import { loginMtsApi } from '../constants/api';
import { Profile } from '../models/profile';

export const getProfile = async (): Promise<Profile | null> => {
    try {
        const result = await api.get<Profile>(`${loginMtsApi}/profile`);
        return result.data;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('profile fetch error');
        return null;
    }
};
