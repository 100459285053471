import { HYDRATE } from 'next-redux-wrapper';
import { ArticleActions, ArticleActionsEnum, ArticleState } from './article-types';
import { getUpdatedState } from './helpers';
import { HydrateAction } from '../hydrate-type';

const initialState = new ArticleState();

const articleReducer = (state = initialState, action: ArticleActions | HydrateAction) => {
    switch (action.type) {
        case ArticleActionsEnum.setCurrentArticle:
            return {
                ...state,
                currentArticle: action.payload,
            };
        case ArticleActionsEnum.updateCacheArticles:
            return getUpdatedState<ArticleState>(action.payload, state, 'articles');
        case ArticleActionsEnum.setCurrentProduct:
            return {
                ...state,
                currentProduct: action.payload,
            };
        case ArticleActionsEnum.setCurrentSection:
            return {
                ...state,
                currentSection: action.payload,
            };
        case ArticleActionsEnum.setArticleRating:
            return {
                ...state,
                articleRating: action.payload,
            };
        case ArticleActionsEnum.setRatingReasons:
            return {
                ...state,
                ratingReasons: action.payload,
            };
        // необходимо указывать, какие поля надо получать с сервера, они будут приоритетом для стора при загрузке страницы
        // https://github.com/kirill-konshin/next-redux-wrapper#usage
        case HYDRATE:
            const hydrateData = action.payload.article;
            const nextState = {
                ...state,
                currentProduct: hydrateData.currentProduct,
                currentSection: hydrateData.currentSection,
                currentArticle: hydrateData.currentArticle,
                isProductHidden: hydrateData.isProductHidden,
            };

            return nextState;
        case ArticleActionsEnum.setIsProductHidden:
            return {
                ...state,
                isProductHidden: action.payload
            };
        default:
            return state;
    }
};

export default articleReducer;
