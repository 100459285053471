import { useEffect, useState } from 'react';

export const useDebounceHook = (value: string | undefined, delay: number): string => {
    const [debouncedValue, setDebouncedValue] = useState<string | undefined>(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue as string;
};
