import { SegmentsEnum } from '../../models/enums/segments.enum';
import { ChildSegment, Segment } from '../../models/segment';

export class ProductsState {
    segment: Segment | null = null;
    currentChildSegment: ChildSegment | null = null;
    currentSegmentCode: SegmentsEnum;
}

export enum SegmentActionsEnum {
    setSegment = 'SET_SEGMENTS',
    setCurrentChildSegment = 'SET_CURRENT_CHILD_SEGMENT',
    setCurrentSegmentCode = 'SET_CURRENT_SEGMENT_CODE',
}

interface SetSegmentAction {
    type: typeof SegmentActionsEnum.setSegment;
    payload: Segment;
}

interface SetCurrentSegmentCodeAction {
    type: typeof SegmentActionsEnum.setCurrentSegmentCode;
    payload: SegmentsEnum;
}

interface SetCurrentChildSegment {
    type: typeof SegmentActionsEnum.setCurrentChildSegment;
    payload: ChildSegment;
}

export type SegmentActions = SetSegmentAction | SetCurrentChildSegment | SetCurrentSegmentCodeAction;
